import mj_axios from "@/mj_axios.js";
import mj_string_utils from "@/misc/mj_string_utils.js"
import mj_csv_utils from "@/misc/mj_csv_utils.js";
import mj_config from "@/mj_config";

var tableMixin = {
    computed: {
        selected_ids: function () {
            return this.selected.map((item) => item.id);
        },
        modelName: function () {
            let ret;
            if (this.modelNameProp) {
                ret = this.modelNameProp;
            } else {
                //lo prende dall'extrapath
                ret = this.$route.params == null ? "" : this.$route.params.id;
            }
            return ret;
        },
        newViewName: function () {
            let ret;
            if (this.newViewNameProp) {
                ret = this.newViewNameProp;
            } else {
                ret = "NewScheda" + this.modelName;
            }
            return ret;
        }
        // COMPUTED REALIZZATO PER LA CUSTOM SEARCH LOCALE
        // searchedItems: function () {
        //   return this.items.filter(item => {

        //     let found = false;
        //     Object.values(item).forEach(val => { found = found || this.customSearch(val, this.search); })
        //     return found;


        //   })
        // }
    },
    data: function () {
        return {
            expanded_row: [],
            items: [],
            headers: [],
            item_meta_data: {},
            selected: [],
            snack_bar_show: false,
            error_text: "",
            dialog_snack_bar_show: false,
            dialog_error_text: "",
            restore_confirm_dialog_show: false,
            delete_confirm_dialog_show: false,
            showExportCsvDialog: false,


            //CSV
            csv_exporting: false,
            csv_headers: [],

            showLoader: false,
            printLoader: false,
            withTrashed: false, //indica se cercare gli items tra quelli cestinati
            search: '',
            last_clicked_item: null, //usati prima di aprire il dialog per capire su quale item agire.
            last_clicked_item_index: null,
            select_all_warning: false, //usato quando gli elementi selezionati visibili sono meno del totale elementi in tabella
            filters: {},

            showSearchHint: false,
            currentPage: 1,
            itemsPerPage: 50,
            pageCount: 1,
            searchedItems: [],
            serverItemsLength: 0,
            // searchTimeoutId: null,

            sortBy: this.sort_by,
            sortDesc: this.sort_desc,
        };
    },
    created() {

        this.initTable();
    },
    methods: {
        resetData() {
            //riassegna tutti i campi dentro data ai valori originali (necessario se cambia la route, infatti essendo sempre lo stesso componente non aggiorna)
            //se dà problema sostituisci con gli assegnamenti manuali
            Object.assign(this.$data, this.$options.data.call(this));

            //copio nelle prop di sort eventuali dati in dataTableOptions
            if (this.dataTableOptions && this.dataTableOptions.sortBy) {
                this.sortBy = this.dataTableOptions.sortBy;

                if (this.dataTableOptions.sortDesc) {
                    this.sortDesc = this.dataTableOptions.sortDesc;
                }
            }
        },
        initTable() {
            this.resetData()
            if (this.loadItemsOnCreated) {
                this.loadItems();
            }
        },
        loadAllSelected() {

            this.showLoader = true;
            this.snack_bar_show = false;
            return new Promise((resolve, reject) => {
                //update query string anche su loadItems
                mj_axios
                    // .get("/api/list/" + this.modelName + "?with_trashed=" + this.withTrashed + "&search_text=" + this.search + "&sort_by=" + (this.sortBy ? this.sortBy : '') + "&sort_desc=" + (this.sortDesc ? this.sortDesc : ''))
                    .get(this.makeLoadItemsUri(false))
                    .then(response => {

                        this.selected = [];
                        const data = response.data.data;

                        if (data) {
                            Object.values(data).forEach(obj => {
                                this.selected.push(obj);
                            });
                        }

                        resolve();
                    })
                    .catch(() => {
                        reject();
                        this.snack_bar_show = true;
                        this.error_text = "Errore nel caricamento dati [select all]";
                    })
                    .then(() => {
                        this.showLoader = false;

                    });
            });
        },
        newItem() {
            // :to="{ name: 'NewScheda' + modelName }"
            // this.$router.push({ name: this.newViewName, params: { id: item.id } });


            this.$router.push({name: this.newViewName});
        },
        makeLoadItemsUri(with_pagination = true) {

            this.search = (this.search == null) ? this.search = '' : this.search;

            let resource_col_name_part = "";
            if (this.resourceCollectionName) {
                resource_col_name_part = "/" + this.resourceCollectionName;
            }

            let filter_array = Object.values(this.filters);

            filter_array = filter_array.concat(this.baseQueryFilter);

            let filters_part = '';
            if (filter_array.length > 0) {

                filters_part = `&filters=${JSON.stringify(filter_array)}`;
            }

            let uri = `${this.apiUrl}${this.modelName}${resource_col_name_part}?with_trashed=${this.withTrashed}&search_text=${this.search}&sort_by=${(this.sortBy ? this.sortBy : '')}&sort_desc=${(this.sortDesc ? this.sortDesc : '')}${filters_part}`;
            if (with_pagination) {
                uri += `&items_per_page=${this.itemsPerPage}&page=${this.currentPage}`;
            }

            return uri;


        },
        loadItems(resetOnLoad, with_pagination = this.enablePagination) {


            //resetOnLoad = false, crea uno svuotamento immediato prima del caricamento tabella (comportamento default)
            //se a true lo svuotamento avviene a caricamento avvenuto (serve a mantenere visibili i dati su modifiche della tabella)
            if (resetOnLoad != true) {
                this.items = [];
            }

            this.showLoader = true;

            this.snack_bar_show = false;

            //update query string anche su loadAllSelected
            mj_axios
                .get(this.makeLoadItemsUri(with_pagination))
                .then(response => {

                    if (resetOnLoad == true) {
                        this.items = [];
                    }

                    const data = response.data.data;

                    if (response.data.item_meta_data) {
                        this.item_meta_data = response.data.item_meta_data;
                    }

                    if (data) {
                        this.csv_headers = [];

                        //determino gli headers per l'esportazione csv
                        if (data.length > 0) {
                            Object.keys(data[0]).forEach(key => {
                                let csv_header = {};
                                csv_header.key = key;
                                csv_header.selected = true; //serve per la selezione delle colonne da esportare su csv e magari in futuro per la visualizzazione solo di certe colonne
                                this.csv_headers.push(csv_header);

                            })
                        }


                        //determino gli headers per la v-table se non li ho determinati nel chiamante del mixin
                        if (data.length > 0 && this.headers.length == 0) {
                            this.headers = [];
                            const default_invisible_cols = ["trashed", this.item_meta_data.expand_field];
                            let invisible_col = null;
                            if (this.hide_cols) {
                                invisible_col = default_invisible_cols.concat(this.hide_cols);
                            } else {
                                invisible_col = default_invisible_cols;
                            }

                            //le colonne invisibili possono essere client side (se definite in this.hide_Cols)
                            //oppure server side se definite negli item_meta_data
                            if (this.item_meta_data.hide_cols) {
                                invisible_col = invisible_col.concat(this.item_meta_data.hide_cols);
                            }

                            Object.keys(data[0]).forEach(key => {

                                // if (key !== this.item_meta_data.expand_field) {
                                if (!invisible_col.includes(key)) {
                                    let header = {};
                                    header.text = mj_string_utils.capitalize(key);
                                    header.value = key;

                                    if (key === "id") {
                                        header.align = "start";
                                    }

                                    this.headers.push(header);
                                }
                            })

                            const actions_header_width = mj_config.user_has_credit_enabled() ? 100 : 56;

                            this.headers.push({
                                text: "",
                                value: "actions",
                                width: actions_header_width,
                                sortable: false,
                            });
                            if (this.item_meta_data.expand_field != null) {
                                this.headers.push({text: '', value: 'data-table-expand'});
                            }
                        }


                        Object.values(data).forEach(obj => {
                            if (obj && obj.trashed) obj.tr_class = 'trashed';
                            // this.$set(this.items, this.items.length-1, obj);
                            this.items.push(obj);
                        });

                        if (response.data.meta && response.data.meta.total) {
                            this.serverItemsLength = response.data.meta.total;
                        } else {
                            this.serverItemsLength = this.items.length;
                        }

                        this.showSearchHint = true;

                    }
                })
                .catch((e) => {


                    this.snack_bar_show = true;
                    let message = (e.response) && (e.response.data) ? e.response.data.message : null;
                    this.error_text = `Errore nel caricamento dati ${message}`;
                })
                .then(() => {
                    this.showLoader = false;

                });
        },
        printItems(with_pagination = this.enablePagination) {

            this.printLoader = true;
            this.snack_bar_show = false;


            //update query string anche su loadAllSelected
            mj_axios
                .get(`${this.makeLoadItemsUri(with_pagination)}&format=pdf`, {responseType: 'blob'})
                .then(response => {

                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Riepilogo Prenotazioni.pdf');
                    document.body.appendChild(link);
                    link.click();

                })
                .catch((e) => {

                    // let message = (e.response) && (e.response.data) ? e.response.data.message : null;
                    // console.log((e.response.data));

                    //in fase di request indichiamo che ci aspettiamo un blob
                    //nel caso di errore riceviamo un json invece (ListController PDF..)
                    //quindi bisogna convertire il blog in json per estrarre il message.

                    let message = '';
                    const fileReader = new FileReader();
                    fileReader.onload =  (evt) => {
                        const response_json = (JSON.parse(evt.target.result))
                        message = response_json.message;
                        this.snack_bar_show = true;
                        this.error_text = `Errore nel caricamento dati ${message}`;
                    };
                    fileReader.readAsText(e.response.data)


                })
                .then(() => {
                    this.printLoader = false;

                });
        },
        //versione usata dal dialog (dove non abbiamo l'item cliccato)
        restoreSingleItemDialog() {
            this.restoreSingleItem(this.last_clicked_item, this.last_clicked_item_index);
        },
        //versione usat direttamente nell'icona di restore dove abbiamo item e index.
        restoreSingleItem(item, index) {

            const params = {"restore": "true"};

            const axios_promise = mj_axios.put(`/api/${mj_string_utils.camelToSnakeCase(this.modelName)}/${item.id}`, params);
            axios_promise.then(() => {

                item.trashed = false;
                item.tr_class = '';
                this.$set(this.items, index, item);

            }).catch(e => {
                this.snack_bar_show = true;
                let message = "";
                if (e.response && e.response.data.data && e.response.data.data.message) {
                    message = e.response.data.data.message;
                }
                this.error_text = "Errore nel ripristino dell'elemento " + message;
            })
                .then(() => {
                    this.showLoader = false;
                    this.restore_confirm_dialog_show = false;
                });
        },
        deleteSelectedItem(item) {
            // if (item != null) return;
            this.showLoader = true;
            this.delete_confirm_dialog_show = false;

            let ids = [];
            //modalità singola
            if (item) {
                if (item.deleting) {
                    return false;
                }
                item.deleting = true;
                ids.push(item.id);
            }
            //modalità array di selezionati
            else {
                ids = this.selected.map(function (item) {
                    return item.id;
                });
            }
            const axios_promise = mj_axios.delete(
                `/api/${mj_string_utils.camelToSnakeCase(this.modelName)}/${JSON.stringify(ids)}?force_delete=auto`
            );
            axios_promise
                .then(() => {
                    for (let i = 0; i < ids.length; i++) {
                        const id = ids[i];
                        if (this.withTrashed) {
                            this.items = this.items.filter(item => {
                                //se l'item era nel cestino devo eliminarlo
                                if (item.trashed) {
                                    return item.id != id;
                                }
                                //se l'item non era nel cestino lo devo segnare come trashed
                                else {
                                    if (item.id == id) {
                                        item.trashed = true;
                                        item.tr_class = 'trashed';
                                    }
                                    return item;
                                }
                            });
                        } else {
                            //rimuove dagli items tutti quelli eliminati.
                            this.items = this.items.filter(item => {
                                return item.id != id;
                            });
                        }
                    }
                })
                .catch(e => {
                    this.snack_bar_show = true;
                    let message = "";
                    if (e.response && e.response.data && e.response.data.message) {
                        message = e.response.data.message;
                    }
                    this.error_text = "Errore nel eliminazione dati " + message;
                })
                .then(() => {
                    this.showLoader = false;
                    if (item) {
                        item.deleting = false;
                    }
                    this.selected = [];

                    this.serverItemsLength -= ids.length;
                });
        },

        deleteSingleItem(item) {
            this.deleteSelectedItem(item);
        },
        editItem(item, index) {
            if (this.item_meta_data.disable_edit_item) {
                return false;
            } else {
                this.last_clicked_item = item;
                this.last_clicked_item_index = index;
                if (item.trashed) {
                    this.restore_confirm_dialog_show = true;
                } else {
                    this.$router.push({name: "Scheda" + this.modelName, params: {id: item.id}});
                }
            }
        },

        /**
         * Utilizzato su emit change event su MjComboBoxFilter
         * Tale componente invia un data con l'oggetto per il filtraggio.
         *
         * L'handler è unico per tutti i MjComboBoxFilter, in base al parametro name contenuto in data
         * si aggiorna il campo filters del mixin
         * @param {*} data
         */
        updateFilters: function (filter_data) {

            let filter_data_array = null;
            if (!Array.isArray(filter_data)) {
                filter_data_array = [filter_data];
            } else {
                filter_data_array = filter_data;
            }

            filter_data_array.forEach(data => {
                    const name = data.name + "_" + data.type; //uso come chiave name + type per evitare collisioni nel caso degli intervalli date (ad esempio data_prenotazione avremo un gte e un lte con lo stesso nome, senza questa concatenzione viene considerato solo l'ultimo)


                    if ((data.type === 'has') || (data.type === 'has_not') || (data.val && data.val.length > 0)) {

                        this.filters[name] = data;
                    } else {


                        delete this.filters[name];
                    }
                }
            );

            this.loadItems(true);


        },

        toggleSelectAll: function (event) {

            if (event.value == true) {
                if (event.items.length < this.serverItemsLength) {
                    this.select_all_warning = true;
                } else {
                    this.select_all_warning = false;
                }
            } else {
                this.select_all_warning = false;
                this.selected = [];
            }

        },
        initCSVDownload() {
            this.showExportCsvDialog = false;
            this.csv_exporting = true;

            const csv_fields = this.csv_headers.filter(header => header.selected == true).map(header => header.key);
            const csv_filename = this.modelName + "_" + new Date().toLocaleString().replace(",", "_").replace("/", "-") + ".csv"

            let csv_data = null;
            //se ho già una selezione considero tale selezione
            if (this.selected && this.selected.length > 0) {
                csv_data = Array.from(this.selected);
                this.downloadCSV(csv_data, csv_fields, csv_filename);
            }
                //se non ce l'ho devo caricare tutti gli items considerando i filtri
            //perchè di default è attiva la paginazione..
            else {
                this.loadAllSelected().then(() => {
                    csv_data = Array.from(this.selected);
                    this.downloadCSV(csv_data, csv_fields, csv_filename);
                })
            }


        },
        downloadCSV: function (csv_data, csv_fields, csv_filename) {
            mj_csv_utils.makeAndDownloadCSV(csv_data, csv_fields, csv_filename)
                .then(() => {
                    this.csv_exporting = false;
                })
                .catch(() => {
                    this.csv_exporting = false;
                    this.snack_bar_show = true;
                    this.error_text = 'Errore nell\'esportazione del CSV';
                });
        }
        // DISABILITATO PERCHE' E' STATO INTRODOTTO IL SEARCH SERVER SIDE
        // customSearch: function (val, search) {
        //   let ret = false;
        //   if (val !== null) {
        //     if (['undefined', 'boolean'].indexOf(typeof val) === -1) {
        //       //a volte negli items potrebbero esserci array o obj (ad esempio per costruire chips..)
        //       if (val.constructor === Array) {
        //         let joined_fields = "";
        //         val.forEach(v => { joined_fields += Object.values(v).join(" "); });
        //         val = joined_fields;
        //       }
        //       else if ((typeof val) === 'object') {
        //         val = Object.values(val).join(" ");
        //       }

        //       ret = val.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        //     }
        //     else {
        //       ret = false;
        //     }
        //   }

        //   return ret;
        // }

    },
    watch: {
        $route() {

            this.initTable();
        }
        ,
        baseQueryFilter: function (oldVal, newVal) {

            //questa watch essendo su un object viene chiamata diverse volte anche se l'object non cambia
            //quindi faccio un confronto tra i json dei due valori per capire se il filtro è cambiato e chiamare il loaditems
            if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
                this.loadItems();
            }


        },
        // search: function () {

        //   //problemi con caricamenti sovrapposti nonostrante il searchtimeoutid
        //   //ad esempio sul created parte una modifica a search..
        //     if (this.searchTimeoutId) {
        //       clearTimeout(this.searchTimeoutId);
        //     }
        //     this.searchTimeoutId = setTimeout(this.loadItems, 1000);
        //   }
        // }
    }
};

export default tableMixin;
