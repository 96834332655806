 <template>
  <v-menu v-model="vmenu" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formatted_model"
        :label="label"
        v-on="on"
        :rules="rules.concat([validation_rule])"
        :prepend-icon="mode === 'date' ? 'mdi-calendar' : 'mdi-clock'"
        @input="onInput"
        @blur="onBlur"
        clearable
        @click:clear="clear()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-if="mode == 'date'"
      v-model="model_value"
      @change="pickerChange"
      locale="it"
    ></v-date-picker>
    <v-time-picker
      v-if="mode == 'time'"
      v-model="model_value"
      @change="pickerChange"
      locale="it"
      format="24hr"
    ></v-time-picker>
  </v-menu>
</template>


<script>
import moment from "moment";

export default {
  name: "MjDateOrTimePicker",
  props: {"model_format":{type:String, default:'YYYY-MM-DD'}, "display_format":{type:String,default:'DD-MM-YYYY'}, "value":{type:String}, "mode":{type:String, default:'date'}, "label":{type:String},"rules":{type:Array, default:() => []}},
  computed: {},
  data: function () {
    return {
      vmenu: false,
      model_value: this.value,
      formatted_model: null,
      validation_rule: (val) => {
        return val == null ||
          val == "" ||
          this.isValidDate(val, this.display_format)
          ? true
          : "formato corretto " + this.display_format;
      },
    };
  },
  mounted() {

    this.model_value = this.value;
    this.formatted_model = this.formatForDisplay(this.model_value);

  },
  methods: {
    clear: function () {
      this.model_value = null;

    },
    formatForDisplay: function (val) {
      const is_valid_date = this.isValidDate(val, this.model_format);
      return is_valid_date
        ? moment(val, this.model_format).format(this.display_format)
        : val;
    },
    isValidDate: function (val, format) {
      return moment(val, format, true).isValid();
    },
    formatForModel: function (val) {
      const is_valid_date = this.isValidDate(val, this.display_format);

      return is_valid_date
        ? moment(val, this.display_format).format(this.model_format)
        : "";
    },
    pickerChange() {
      this.vmenu = false;
      this.onInput();
    },
    onInput(val) {
      if (
        !val ||
        val == "" ||
        this.isValidDate(this.model_value, this.model_format)
      ) {
        this.$emit("input", this.model_value);
      }
    },
    onBlur() {
      if (this.isValidDate(this.model_value, this.model_format)) {
        this.$emit("input", this.model_value);
      }
    },
  },
  watch: {
    value: function () {

      this.model_value = this.value;
      this.formatted_model = this.formatForDisplay(this.model_value);
    },

    formatted_model: function (val) {
      this.model_value = this.formatForModel(val);
    },
  },
};
</script>