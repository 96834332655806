<template>
  <v-card class="mt-6" elevation="0" color="transparent">


    <v-card-title>Acquista un Buono Regalo</v-card-title>
    <v-card-subtitle>Potrai acquistare un buono regalo, direttamente online, da regalare ai tuoi cari.
    </v-card-subtitle>

    <v-card-text>

      Dopo l'acquisto online verrà generato un codice coupon che potrai consegnare in cassa per riscattare il Buono
      Regalo!

      <v-form ref="form">
        <v-text-field label="Inserisci La tua email" v-model="buyer_email" :rules="[rules.required, rules.email]"></v-text-field>
        <div class="mj_label mt-4 mb-4">Scegli L'importo</div>
        <v-btn-toggle
            mandatory
            v-model="selected_coupon_amount"
        >
          <v-btn v-for="item in available_coupon_amounts" :key="item.value" :value="item.value">
            {{ item.description }}
          </v-btn>
        </v-btn-toggle>
      </v-form>

      <div v-show="last_generated_code">

        <v-card-title class=" pl-0">Il Codice Generato è:
          <v-spacer></v-spacer>
          <span class="font-weight-bold primary--text">{{ last_generated_code }}</span></v-card-title>
        <v-card-subtitle class=" pl-0">Riceverai una mail di conferma che potrai stampare o inoltrare al destinatario
          del tuo regalo
        </v-card-subtitle>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-row justify="center">
        <v-btn class="primary" large :loading="buying >0" @click="buyCreditCoupon()">Acquista Adesso</v-btn>
      </v-row>
    </v-card-actions>

    <MjStripeDialog dialog_title="Acquisto Buono Regalo"
                    v-model="show_stripe_dialog"
                    :price="selected_coupon_amount"
                    confirm_button_text="Acquista"
                    charge_type="COUPON"
                    :purchase_additional_data="{buyer_email:this.buyer_email}"
                    @payment-cancel="show_stripe_dialog = false"
                    @payment-done="show_stripe_dialog = false; show_purchase_confirmation = true"></MjStripeDialog>
<!--    show_purchase_confirmation-->
    <v-row class="mx-3 mt-4 font-weight-bold">
    <v-alert v-model="show_purchase_confirmation" class="text-center" type="success">Il tuo acquisto è andato a buon fine, riceverai una mail con il Codice del Buono Regalo</v-alert>
    </v-row>
  </v-card>
</template>

<script>


import MjStripeDialog from "@/components/payment/MjStripeDialog";
export default {
  name: "BuyCreditCouponView",
  components: {MjStripeDialog}
}
</script>

<style scoped>

</style>

<script>

import validationRulesMixin from "@/mixins/ValidationRulesMixin";
import MjStripeDialog from "@/components/payment/MjStripeDialog.vue";
import mj_axios from "@/mj_axios";

export default {
  name: "BuyCreditCouponView",
  mixins: [validationRulesMixin],
  components: {MjStripeDialog},

  data: () => ({
    available_coupon_amounts: [{description: '10€', value: 1000}, {
      description: '30€',
      value: 3000
    }, {description: '50€', value: 5000}, {description: '100€', value: 10000}],
    buying: 0,
    buyer_email: '',
    last_generated_code: '',
    last_generated_date: '',
    selected_coupon_amount: '',
    show_stripe_dialog: false,
    show_purchase_confirmation: false,
  }),
  computed: {},
  methods: {


    buyCreditCoupon() {
      if (this.$refs.form.validate()) {
        this.show_purchase_confirmation = false;
        this.show_stripe_dialog = true;

      }
    },

  },
  watch: {}
}
;
</script>