<template>
  <v-container>
    <v-card >
      <v-card-title>Riscatto Coupon</v-card-title>
      <v-card-text>
        <v-text-field :loading="checking_code > 0" @input="checkCreditCouponCode" v-model="credit_coupon_code"
                      prefix="M-" :hint="credit_coupon_code_hint" label="Inserisci Il Codice"
                      :append-icon="code_icon" counter>
        </v-text-field>

        <v-autocomplete label="Utente" v-model="selected_receiver_user"
                        :item-text="(user) => `${user.email} - ${user.name}`" item-value="id" :items="this.users"
                        cache-items
                        :rules="[rules.required]"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>

        <v-spacer></v-spacer>
        <v-btn :loading="redeem_invalidate_loading>0" class="warning mr-2"
               @click="redeem('ADMIN_REDEEM_AND_INVALIDATE')">Invalida
        </v-btn>
        <v-btn :loading="redeem_prepaid_loading>0" class="primary " @click="redeem('ADMIN_REDEEM_IN_PREPAID')">Riscatta
          e Ricarica
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>Genera Nuovo Coupon</v-card-title>
      <v-card-subtitle>Usa questa funzione per generare un codice coupon da vendere direttamente in cassa
      </v-card-subtitle>

      <v-card-text>
        <v-form ref="form">
          <v-text-field label="Inserisci La mail d'acquirente" v-model="generate_code_buyer_email"></v-text-field>

          <v-text-field label="Inserisci il Valore del coupon" v-model="generate_code_credit_value" prefix="€">
          </v-text-field>

          <v-textarea v-model="generate_code_note" rows="2" label="Nota"></v-textarea>
        </v-form>

        <div v-show="last_generated_code">

          <v-card-title class=" pl-0">Ultimo Codice Generato:
            <v-spacer></v-spacer>
            <span class="font-weight-bold primary--text">{{ last_generated_code }}</span></v-card-title>
          <v-card-subtitle class=" pl-0">Alle: {{ last_generated_date }}</v-card-subtitle>
        </div>
      </v-card-text>
      <v-card-actions>

        <v-spacer></v-spacer>
        <v-btn class="primary " :loading="generating_code >0" @click="generate()">Genera</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style>

</style>
<script>


import validationRulesMixin from "@/mixins/ValidationRulesMixin";
import mj_axios from "@/mj_axios";

export default {
  name: "UsersTableView",
  mixins: [validationRulesMixin],
  components: {},
  beforeMount() {
    this.loadUsers();
  },
  data: () => ({
    code_icon: '',
    credit_coupon_code: null,
    credit_coupon_code_hint: '',
    checking_code: 0,
    generating_code: 0,
    selected_receiver_user: null,
    last_generated_code: '',
    last_generated_date: '',
    redeem_invalidate_loading: 0,
    redeem_prepaid_loading: 0,
    generate_code_buyer_email: '',
    generate_code_credit_value: 0,
    generate_code_note: '',
    users: []
  }),
  computed: {},
  methods: {
    loadUsers: function () {
      this.loading++;
      const axios_promise = mj_axios.get("/api/user/users");
      axios_promise.then(response => {
        this.users = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    },
    checkCreditCouponCode: function () {

      if (this.credit_coupon_code.length == 10) {
        this.checking_code++;
        this.credit_coupon_code_hint = '';
        const axios_promise = mj_axios.get(`/api/admin/credit_coupon/findBy?code=M-${this.credit_coupon_code}`);
        axios_promise.then(response => {
          const ret = response.data;

          if (ret === 1) {
            this.credit_coupon_code_hint = 'Il codice è valido e pronto per essere riscattato';
            this.code_icon = 'mdi-check'
          } else if (ret === 2) {
            this.credit_coupon_code_hint = 'Il codice è stato già riscattato';
            this.code_icon = 'mdi-alert-octagram-outline'
          }

        }).catch((e) => {

          const status = (e.response) ? e.response.status : null;
          if (status == 404) {
            this.credit_coupon_code_hint = 'Il codice non esiste';
            this.code_icon = 'mdi-alert-outline'
          } else {
            this.$store.commit("update_modal_dialog", {
              title: "Attenzione!!",
              text: "Errore nel controllo del codice coupon durante la digitazione, se l'errore persiste contattare l'assistenza tecnica.",
              visible: true,
            });
          }
        }).then(() => {
          this.checking_code--;
        });
        return axios_promise;
      } else {
        this.credit_coupon_code_hint = 'Il codice deve essere 10 caratteri';
        this.code_icon = 'mdi-alert-outline'
      }
    },
    redeem(redeem_type) {
      if (redeem_type === 'ADMIN_REDEEM_AND_INVALIDATE') {
        this.redeem_invalidate_loading++;
      } else {
        this.redeem_prepaid_loading++;
      }

      const params = {
        "code": 'M-' + this.credit_coupon_code,
        "user_id": this.selected_receiver_user,
        redeem_type: redeem_type
      };
      const axios_promise = mj_axios.post('/api/admin/credit_coupon/invalidate', params);
      axios_promise.then(response => {
        const ret = JSON.parse(response.data);
        let message = '';
        if (ret === -3) {
          message = 'Il codice è stato già riscattato in passato'
        } else if (ret === -2) {
          message = 'Il codice NON è valido'
        } else if (ret === 1) {
          message = "Codice Riscattato Correttamente";
        }

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: message,

          visible: true,
        });
      }).catch((e) => {
            let message = (e.response) && (e.response.data) ? e.response.data.message : null;
            if (!message) {
              message = "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.";
            }
            this.$store.commit("update_modal_dialog", {
              title: "Attenzione!!",
              text: message,

              visible: true,
            });
          }
      ).then(() => {
        if (redeem_type === 'ADMIN_REDEEM_AND_INVALIDATE') {
          this.redeem_invalidate_loading--;
        } else {
          this.redeem_prepaid_loading--;
        }
      });
      return axios_promise;
    },
    generate() {
      this.generating_code++;
      const params = {
        "buyer_email": this.generate_code_buyer_email,
        "credit_value": this.generate_code_credit_value,
        "note": this.generate_code_note,

      };
      const axios_promise = mj_axios.post('/api/admin/credit_coupon/generate', params);
      axios_promise.then((response) => {
        const credit_coupon = response.data;
        this.last_generated_code = credit_coupon.code;
        this.last_generated_date = new Date().toLocaleTimeString();
      }).catch((e) => {
            let message = (e.response) && (e.response.data) ? e.response.data.message : null;
            if (!message) {
              message = "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.";
            }
            this.$store.commit("update_modal_dialog", {
              title: "Attenzione!!",
              text: message,

              visible: true,
            });
          }
      ).then(() => {
        this.generating_code--;
      });
      return axios_promise;
    },

  },
  watch: {}
}
;
</script>
