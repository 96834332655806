/*
My Account View, serve per gestire i dati di un account.
Mostra un testo standard personalizzabile replicando la MyAccountView con una CustomMyAccountView da rimappare sul custom.router.js

Si appoggia al componente MjMyAccount per ottenere i dati e comunicarli tramite ajax al b.e.
Il componente MjMyAccount è espandibile grazie allo slot: Per cui di default avrà i campi email e password, con lo slot
potrai aggiungere nome, cognome etc.

Esempio di uso degli slot per aggiungere il nome

<MjMyAccount>
<template v-slot:additional_fields="{ form }">
  <v-text-field outlined label="Nome" v-model='form["nome"]'>
  </v-text-field>
</template>
</MjMyAccount>

*/

<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="8">
        <h1 class="text-center mb-2">Il mio account</h1>
        <h3 class="text-center mb-4">Modifica il tuo profilo..</h3>
        <mj-my-account :additional-form-fields="additionalFormFields">
          <template v-slot:additional_fields="{ form }">
            <v-text-field label="Cognome" v-model='form["cognome"]["val"]'>
            </v-text-field>
            <MjDateOrTimePicker
                label="Data Nascita" v-model='form["data_nascita"]["val"]'
            />
            <v-text-field label="Nickname" v-model='form["nickname"]["val"]'></v-text-field>
            <v-radio-group
                v-model="form['sesso']['val']"
                row
            >
              <v-radio
                  label="Maschio"
                  value="M"
              ></v-radio>
              <v-radio
                  label="Femmina"
                  value="F"
              ></v-radio>
            </v-radio-group>
            <v-text-field label="Cellulare" v-model='form["cellulare"]["val"]' :rules="[rules.phone]">
            </v-text-field>
            <v-text-field label="Luogo di Nascita" v-model="form['luogo_nascita']['val']"></v-text-field>
            <v-text-field label="Nazione Provenienza" v-model='form["nazione_provenienza"]["val"]'>
            </v-text-field>

            <v-switch
                v-model='form["send_email_risultati"]["val"]'
                label="Autorizzo a ricevere Email con Risultati"
            ></v-switch>

            <v-switch
                v-model='form["send_email_marketing"]["val"]'
                label="Autorizzo a ricevere Email con finalità di Marketing"
            ></v-switch>

            <v-switch
                v-model='form["send_sms_marketing"]["val"]'
                label="Autorizzo a ricevere SMS con finalità di Marketing"
            ></v-switch>
          </template>
        </mj-my-account>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MjMyAccount from "@/components/MjMyAccount.vue";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjDateOrTimePicker from "@/components/form/MjDateOrTimePicker";

export default {
  name: "MyAccountView",
  components: {MjDateOrTimePicker, MjMyAccount},
  mixins: [ValidationRulesMixin],

  data: () => ({
    additionalFormFields: {
      cognome: {type: "string", val: ""},
      cellulare: {type: "string", val: ""},
      nickname: {type: "string", val: ""},
      sesso: {type: "string", val: ""},
      data_nascita: {type: "date", val: ""},
      luogo_nascita: {type: "string", val: ""},
      nazione_provenienza: {type: "string", val: ""},
      send_email_risultati: {type: "boolean", val: ""},
      send_email_marketing: {type: "boolean", val: ""},
      send_sms_marketing: {type: "boolean", val: ""},

    }
  }),
  methods: {}
};
</script>
