import axios from "axios";
import store from "@/store.js"
import mj_config from "@/mj_config.js"
/**
 * axios instance
 */
let mj_axios = axios.create({
    baseURL: mj_config.getBaseUrl()
})

mj_axios.interceptors.request.use(
    config => {
        config.headers['Authorization'] = 'Bearer ' + store.state.token_obj.access_token;
        return config;
    },
    error => {
        Promise.reject(error);
    });

// response parse
mj_axios.interceptors.response.use((response) => {
    return response
},
    function (error) {

        // if (error != null && error.response != null && error.response.status === 401) {
        //     store.commit('update_modal_dialog',{title:'Attenzione',text:'Non sei autorizzato a visualizzare questo contenuto <br> Se non sei loggato o devi cambiare ruolo <a href="/utente/login">Vai al Login</a>',visible:true});
        // }

        //TODO aggiungi logica di refresh

        // return Error object with Promise
        return Promise.reject(error);
    });

mj_axios.download = function(url,name) {
    mj_axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
    
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', name);
         document.body.appendChild(fileLink);
         fileLink.click();
    });
}

mj_axios.open = function(url,target) {
    mj_axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
    
        let content_type = "";
        if (response && response.headers)
        {
            content_type = response.headers["content-type"];
        }
        
        const file = new Blob([response.data], { type: content_type});
         
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL,target);
    });
}

export default mj_axios;