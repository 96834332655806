<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <h1>Informazioni Generiche di Prodotto</h1>
        <div>Indica le informazioni generiche per una tipologia di prodotto (es. Kart, Privati, etc)</div>
        <v-divider class="my-4"></v-divider>
        <v-row>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Cod'
                v-model='form.cod.val'
                :rules="[rules.max(6)]"
                hint="Usa il codice EV per gli eventi (mostre etc.) che vuoi mostrare all'utente"
            >

            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Titolo'
                v-model='form.title.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12'>
            <v-textarea
                label='Descrizione'
                v-model='form.description.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
          <v-col cols='12' md='4'>
            <div>Colore Prodotto</div>
            <v-color-picker

                v-model='form.color.val'
                hide-inputs
                mode="rgba"
                swatches-max-height="200"
            >
            </v-color-picker>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Icona'
                v-model='form.icon.val'
                :rules="[rules.max(32)]"
                :append-outer-icon="form.icon.val"
            >
            </v-text-field>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12">
<!--            tolta per evitare confusione con quella nelle categorie-->
            <mj-image text="P"
                      v-if="false"
                      ref="product_image"
                      :image-src="form.image_base64.val"
                      @img-changed="updateImage"
                      shape="rectangle"
                      :width="320"
                      :height="240"
            >
            </mj-image>
          </v-col>
        </v-row>

        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            :new_button_visibile="true"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjImage from "@/components/form/MjImage";


export default {
  name: "SchedaProductInfo",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjImage, MjSchedaToolbar,},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      title: {type: 'string', val: '', default_val: ''},
      description: {type: 'text', val: '', default_val: ''},
      icon: {type: 'string', val: '', default_val: ''},
      color: {type: 'string', val: '', default_val: ''},
      image_base64:{type: 'string', val: '', default_val: ''},
    },
    apiUrl: "/api/product_infos"
  }),

  methods: {
    updateImage(image){
      this.form.image_base64.val = image;
    },
    newItem(){
      this.$refs.product_image.resetImage();
      schedaMixin.methods.newItem.call(this);
    }
  }
};
</script>
