<template>
  <v-container>
    <MjUserCredit >
      Il credito può essere utilizzato per usufruire dei servizi presenti sul Sito.
      Con il prepagato non dovrai ogni volta inserire i dati della tua carta per prenotare e soprattutto può darti
      diritto a sconti speciali!
    </MjUserCredit>
  </v-container>
</template>

<script>

import MjUserCredit from "@/components/payment/MjUserCredit.vue";

export default {
  name: "MyCreditView",
  components: {MjUserCredit},


  computed: {},
  data: () => ({

  }),

  methods: {
    

  },
  watch: {},
};
</script>
