<template>
  <v-container>
    <MjTableItems
        ref="table_booking"
        model-name-prop="Booking"
        resource-collection-name="BookingForAdminResourceListCollection"
        sort_by="Data"
        :loadItemsOnCreated="false"
        :enablePrint="true"
    >
      <template slot="mj_filters_section">
        <MjFilterGroup
            :expand_filter="true"
            class="mt-0 px-2"
            @reset-all="
            $refs.table_booking.filters = [];
            $refs.table_booking.loadItems(true);
          "
        >

          <template slot="filters" slot-scope="props">
            <v-row>
              <v-col>
                <MjDateIntervalFilter
                    label-from="Da"
                    label-to="A"
                    time-model-format="HH:mm:ss"
                    time-display-format="HH:mm"
                    date-model-format="YYYY-MM-DD"
                    date-display-format="DD/MM/YYYY"
                    mode="datetime"
                    glue="and"
                    @change="updateFilters"
                    :silent_reset="props.reset_all"
                    filter_name="start"
                    :valueFrom="filter_from"
                    :valueTo="filter_to"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="6">
                <MjComboBoxFilter
                    label="Prodotto"
                    load_items_url="/api/list/ProductItem/ProductItemResource4SelectCollection"
                    filter_name="product_item_cod"
                    :item_description_field="(item) => `${item.cod} - ${item.description}`"
                    item_value_field="cod"
                    glue="and"
                    @change="updateFilters"
                    :silent_reset="props.reset_all"
                />
              </v-col>
              <v-col cols="6">
                <MjToggleFilter
                    label="Stato Prenotazione"
                    small
                    :items_prop="[{description:'Inserita',value:1}, {description:'Annullata', value:-1}]"
                    filter_name="booking_status"
                    :item_description_field="(item) => `${item.description}`"
                    item_value_field="value"
                    glue="and"
                    @change="updateFilters"
                    :silent_reset="props.reset_all"
                />
              </v-col>
            </v-row>
          </template>
        </MjFilterGroup>
      </template>
    </MjTableItems>
  </v-container>
</template>

<script>

import MjTableItems from "@/components/MjTableItems";

import MjComboBoxFilter from "@/components/filters/MjComboBoxFilter.vue";
import MjToggleFilter from "@/components/filters/MjToggleFilter.vue";
import MjFilterGroup from "@/components/filters/MjFilterGroup.vue";
import MjDateIntervalFilter from "@/components/filters/MjDateIntervalFilter.vue";
import moment from "moment";
// import schedaMixin from "@/mixins/SchedaMixin";

export default {
  name: "BookingHistoryView",
  mixins: [],
  components: {MjTableItems, MjDateIntervalFilter, MjFilterGroup, MjToggleFilter, MjComboBoxFilter},
  data: () => ({
    filter_from: null,
    filter_to: null,

  }),

  methods: {

    //ridefinito altrimenti non lo trova prima del rendering di TableItems
    //quindi uso il ref.table_booking per richiamarne l'implementazione effettiva
    updateFilters: function (data) {


      console.log('update filters');

      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        this.$refs.table_booking.updateFilters(data);
      })


    },
  },
  watch: {
    $route: {
      handler: function () {
        if (this.$route.query.date) {
          const date_moment = moment(this.$route.query.date);
          this.filter_from = date_moment.format('YYYY-MM-DD 00:00:00');
          this.filter_to = date_moment.add(1,'day').format('YYYY-MM-DD 04:00:00');
          console.log(this.filter_to);
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
