import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import mj_config from "../mj_config";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark:mj_config.dark_mode_on(),
        themes: {
            light: {
               primary: '#c43286' ,
               //primary: '#2196f3',
               secondary: '#30a08f',
               //secondary: '#FF9A13',
               danger:"#EF5350",
            },
            dark: {
                primary: '#DC0003' ,
                //primary: '#2196f3',
                secondary: '#543131',
                //secondary: '#FF9A13',
                warning:'#473571',
                error:'#00D1EC',
                danger:'#00D1EC',
                info:'#326abf',
             }
        }
    }
});
