<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <v-row>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Cod'
                v-model='form.cod.val'
                :rules="[rules.max(6)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='10'>
            <v-text-field
                label='Titolo'
                v-model='form.title.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12'>
            <v-textarea
                label='Descrizione'
                v-model='form.description.val'
                rows='2'
            >
            </v-textarea>
          </v-col>

          <v-col cols='12'>
            <v-switch
                label='Il più venduto'
                :true-value="1"
                :false-value="0"
                v-model="form.best_seller.val"
            >
            </v-switch>
          </v-col>

        </v-row>

        <v-row class="mt-4">
          <mj-image
              ref="session_type_image"
              :image-src="form.image_base64.val"
              @img-changed="updateImage"
              shape="rectangle"
              width="100%"
              :height="240"
          >
          </mj-image>
        </v-row>

        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjImage from "@/components/form/MjImage.vue";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";


export default {
  name: "SchedaProductSessionType",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar,MjImage},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      title: {type: 'string', val: '', default_val: ''},
      best_seller: {type: 'integer', val: '', default_val: ''},
      description: {type: 'text', val: '', default_val: ''},
      image_base64:{type: 'string', val: '', default_val: ''},
    },
    apiUrl: "/api/product_session_types"
  }),

  methods: {
    updateImage(image){
      this.form.image_base64.val = image;
    },
    newItem(){
      this.$refs.session_type_image.resetImage();
      schedaMixin.methods.newItem.call(this);
    }
  }
};
</script>
