const mj_csv_utils = {
  makeAndDownloadCSV(objects_array, fields = null, filename = 'exported_data.csv', separator = ';') {
    return new Promise(function (resolve) {
      let purged_objects_array = null;
      if (fields) {
        purged_objects_array = objects_array.map(function (obj) {
          let purged_obj = {};
          fields.forEach(f => {
            purged_obj[f] = obj[f];
          });
          return purged_obj;
        });
      } else { purged_objects_array = objects_array; }


      let csvContent = "data:text/csv;charset=utf-8,";
      // csvContent += Object.keys(objects_array[0]).join(separator) + "\n";
      // for (let i = 1; i < objects_array.length; i++)
      // {
      //   for (const [ value] of Object.entries(objects_array[i])) {
      //     // console.log(ke

      //     csvContent += value + separator;
      //   }
      //   csvContent +="\n";
      // }
      // csvContent += ']';

      csvContent += [
        Object.keys(purged_objects_array[0]).join(separator),
        ...purged_objects_array.map(item => Object.values(item).map((value) => 
        { if (typeof value === 'object') 
        { return JSON.stringify(value); } 
        else { 
         return value; } }).join(separator))
      ]
        .join("\n")
        // .replace(/(^\[)|(\]$)/gm, "")
        ;




      const data = encodeURI(csvContent).replace("#","%23");

      
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", filename);
      link.click();

      resolve();
    });


  },


}

export default mj_csv_utils;