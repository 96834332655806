<template>
  <v-container>
    <v-data-table
        v-model="selected"
        :options="dataTableOptions"
        :headers="headers"
        :items="items"
        :disable-pagination="!enablePagination"
        :hide-default-footer="hideFooter"
        :server-items-length="serverItemsLength"
        :page.sync="currentPage"
        :items-per-page.sync="itemsPerPage"
        @page-count="pageCount = $event"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="showLoader"
        :show-select="
        (item_meta_data.is_selectable || item_meta_data.is_erasable) &&
        items.length > 0
      "
        :show-expand="!!this.item_meta_data.expand_field"
        :expanded.sync="expanded_row"
        :item-class="(item) => item.tr_class"
        no-results-text="Nessun Elemento Trovato.."
        class="elevation-1 mj_table_items"
        @click:row="clickRowHandler"
        @toggle-select-all="toggleSelectAll"
        @update:page="loadItems(true)"
        @update:items-per-page="loadItems(true)"
        @update:sort-by="loadItems(true)"
        @update:sort-desc="loadItems(true)"
        :footer-props="{
        'items-per-page-options': [50, 100, 500],
      }"
    >
      <template v-slot:no-data>
        Nessun Elemento da Mostrare
        <v-row>
          <v-col cols="12" v-if="showWithTrashed">
            <v-btn
                v-if="!withTrashed"
                small
                depressed
                outlined
                @click="
                if (!showLoader) {
                  withTrashed = !withTrashed;
                  loadItems();
                }
              "
            >
              Cerca anche Nel Cestino
              <v-icon right>mdi-delete-empty</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-icon class="mr-1">{{ item_meta_data.icon }}</v-icon>
          <v-toolbar-title
          >Gestione {{ item_meta_data.item_plural_name }}
          </v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-row>
            <v-col cols="12" class="text-right">
              <slot
                  name="mj_top_toolbar_prepend"
                  :selected_ids="selected_ids"
                  :selected="selected"
              ></slot>
              <v-tooltip top v-if="csv_global_export_enabled">
                <template v-slot:activator="{ on }">
                  <v-progress-circular
                      v-show="csv_exporting"
                      indeterminate
                      color="green"
                      class="ml-2"
                  ></v-progress-circular>

                  <v-btn
                      v-show="csv_exporting == false"
                      fab
                      small
                      color="green white--text"
                      class="ml-2"
                      v-on="on"
                      @click="showExportCsvDialog = true"
                  >
                    <v-icon>mdi-table-arrow-down</v-icon>
                  </v-btn>
                </template>
                <span>Esporta in Formato CSV (Excel Compatibile)</span>
              </v-tooltip>
              <v-tooltip top v-if="selected.length > 0">
                <template v-slot:activator="{ on }">
                  <v-btn
                      fab
                      small
                      color="danger white--text"
                      class="ml-2"
                      v-on="on"
                      @click="delete_confirm_dialog_show = true"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Elimina Tutti</span>
              </v-tooltip>

              <v-tooltip top v-if="enablePrint">
                <template v-slot:activator="{ on }">
                  <v-btn
                      :loading="printLoader"
                      :disabled="printLoader"
                      fab
                      small
                      v-on="on"
                      @click="printItems()"
                      class="ml-2 blue-grey darken-3"
                      v-shortkey="['meta', 'p']"
                      @shortkey="printItems()"
                  >
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Stampa</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                      fab
                      small
                      v-on="on"
                      @click="loadItems()"
                      :disabled="showLoader"
                      class="ml-2"
                      v-shortkey="['meta', 'r']"
                      @shortkey="loadItems()"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Ricarica</span>
              </v-tooltip>

              <v-btn
                  color="primary"
                  fab
                  small
                  @click="
                  $emit('new-item-click');
                  newItemHandler();
                "
                  v-shortkey="['meta', '+']"
                  @shortkey="$router.push({ name: 'NewScheda' + modelName })"
                  class="ml-2"
                  v-if="item_meta_data.can_add_new"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <slot name="mj_filters_section"></slot>
        <v-row class="mx-auto mb-4">
          <v-col cols="12">
            <v-text-field

                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                :hint="
                showSearchHint
                  ? 'Trovati ' + serverItemsLength + ' Elementi'
                  : ''
              "
                persistent-hint
                single-line
                clearable
                v-on:input="showSearchHint = false"
                v-on:keyup.enter="
                currentPage = 1;
                loadItems(true);
              "
                :append-outer-icon="showWithTrashed ? (withTrashed ? 'mdi-delete-empty' : 'mdi-delete') : ''"
                @click:clear="
                currentPage = 1;
                search = '';
                loadItems(true);
              "
                @click:append-outer="
                if (!showLoader) {
                  withTrashed = !withTrashed;
                  loadItems();
                }
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-show="selected.length > 0">
          <v-col cols="12">
            <v-alert type="info" dense>
              Hai selezionato {{ selected.length }} elementi.<a
                href="#"
                @click.prevent="selected = []"
                class="white--text text-sm float-right"
            >
              Annulla Selezione</a
            >
              <br/>
              <a
                  href="#"
                  @click.prevent="
                  loadAllSelected();
                  select_all_warning = false;
                "
                  v-show="select_all_warning"
                  class="white--text"
              >Clicca per selezionare tutti {{ serverItemsLength }} Elementi
                Trovati</a
              >
            </v-alert>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.actions`]="{ item, index }">
        <v-row justify="start" class="actions_cell">
          <slot name="custom_actions" :item="item"></slot>
          <v-tooltip top :color="item.trashed ? 'danger' : 'warning'">
            <template v-slot:activator="{ on }">
              <v-icon
                  v-on="on"
                  v-if="!!item_meta_data.is_erasable"
                  @click.prevent.stop="deleteSingleItem(item)"
              >{{
                  item.trashed ? "mdi-delete-forever" : "mdi-delete"
                }}
              </v-icon
              >
            </template>
            <span>{{
                item.trashed ? "Elimina Definitivamente" : "Sposta nel Cestino"
              }}</span>
          </v-tooltip>

          <v-tooltip top color="info">
            <template v-slot:activator="{ on }">
              <v-icon
                  v-on="on"
                  v-if="item.trashed"
                  @click.prevent.stop="restoreSingleItem(item, index)"
              >mdi-archive-arrow-up
              </v-icon
              >
            </template>
            <span>Ripristina Elemento</span>
          </v-tooltip>

        </v-row>
      </template>

      <template
          v-if="!!this.item_meta_data.expand_field"
          v-slot:expanded-item="{ headers, item }"
      >
        <td
            class="expanded_cell pa-3 primary lighten-2 white--text"
            :colspan="headers.length"
        >
          {{ item[item_meta_data.expand_field] ? item[item_meta_data.expand_field] : "Nessun Dettaglio Da Mostrare" }}
        </td>
      </template>

      <template v-for="status_col in item_meta_data.status_cols" v-slot:[`item.${status_col}`]="{ item }">
        <v-row
            :key="status_col"
            v-if="item[status_col]"
            :style="{backgroundColor:item[status_col].bg_color}"
            justify="center"
            style="height: 100%"
        >
          <v-col :style="{color:item[status_col].text_color}">

            <v-icon :style="{color:item[status_col].text_color}" >{{
                item[status_col].icon
              }}
            </v-icon>
            {{ item[status_col].text }}

          </v-col>
        </v-row>
      </template>

      <!--v-for per riportare tutti gli slot presenti nel parent-->
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>

      <template v-slot:[`body.append`]="{}">
        <!-- <v-row align="center" > -->
        <!-- <v-col cols="12"> -->
        <tr v-show="showLoader">
          <td colspan="1000" style="height: 4px" class="mx-0 px-0">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
        <!-- </v-col> -->
        <!-- </v-row> -->
      </template>

      <!-- <template v-slot:no-data>
        <v-alert type="info" prominent class="my-4 w-100" outlined width="100%" dense>
          <v-row align="center" >
            <v-col class="grow">Nessun Dato Disponibile</v-col>
            <v-col class="shrink">
              <v-btn color="primary" @click="loadItems" :disabled="showLoader">Ricarica</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>-->
    </v-data-table>

    <v-row>
      <!--vrow necessaria per la width 100%-->
      <v-snackbar
          app
          v-model="snack_bar_show"
          type="warning"
          width="100%"
          color="info"
          vertical="vertical"
      >{{ error_text }}
      </v-snackbar
      >
    </v-row>

    <MjConfirmDialog
        v-model="delete_confirm_dialog_show"
        @confirm-from-dialog="deleteSelectedItem"
        @cancel-from-dialog="delete_confirm_dialog_show = false"
        dialog_title="Attenzione"
        dialog_text="Sicuro di voler eliminare gli elementi selezionati?"
    ></MjConfirmDialog>

    <MjConfirmDialog
        v-model="restore_confirm_dialog_show"
        @confirm-from-dialog="restoreSingleItemDialog"
        @cancel-from-dialog="restore_confirm_dialog_show = false"
        dialog_title="Attenzione"
        dialog_text="L'elemento è nel cestino, prima di modificarlo devi ripristinarlo, vuoi procedere con il ripristino?"
    ></MjConfirmDialog>

    <MjConfirmDialog
        v-model="showExportCsvDialog"
        @confirm-from-dialog="initCSVDownload"
        @cancel-from-dialog="showExportCsvDialog = false"
        dialog_title="Esportazione CSV"
        dialog_text="Scegli i Campi"
        v-if="items != null && items.length > 0"
    >
      <v-list :max-height="200" style="overflow: auto" dense>
        <v-row align="start" justify="start">
          <template v-for="(csv_header, i) in csv_headers">
            <v-col
                style="flex-grow: 0"
                :key="i"
                v-if="csv_header && csv_header.key && csv_header.key.length > 0"
            >
              <v-checkbox
                  v-model="csv_header.selected"
                  color="primary"
                  :label="csv_header.key"
              ></v-checkbox>
            </v-col>
          </template>
        </v-row>
      </v-list>
    </MjConfirmDialog>
  </v-container>
</template>
<style scoped>
.expanded_cell {
  background-color: white;
  max-width: 10px;
}

/* workaround per il problema di disallineamento degli header, la freccia di sort in base allo spazio và a destra o in basso, creando un effetto scalino tra le intestazioni della tabella, con block riporto tutte le arrow a capo */
.mj_table_items table th i {
  display: block !important;
}

.mj_table_items table th .v-data-table__checkbox {
  margin-bottom: 20px;
}

/*fine workaround */

.mj_table_items table tr {
  cursor: pointer;
}

.mj_table_items tr.trashed {
  /*background-color: #eee;*/
  opacity: 0.2;
}

.actions_cell {
  width: auto;
}
</style>

<script>
import MjConfirmDialog from "@/components/MjConfirmDialog.vue";
import tableMixin from "@/mixins/TableMixin.js";
import mj_config from "@/mj_config.js";

export default {
  components: {MjConfirmDialog},
  props: {
    "apiUrl": {default: () => '/api/list/'},
    "enable-pagination": {default: true},
    "hide-footer": {default: false},
    modelNameProp: {type: String},
    resourceCollectionName: {type: String},
    newViewNameProp: {type: String},
    customEditItem: {type: Function},
    customDeleteItems: {type: Function},
    customNewItem: {type: Function},
    baseQueryFilter: {type: Array, default: () => []},
    hide_cols: {type: Array},
    dataTableOptions: {
      type: Object, default: () => {
      }
    },
    loadItemsOnCreated: {type: Boolean, default: () => true},
    enablePrint: {type: Boolean, default: () => false},
    showWithTrashed: {type: Boolean, default: () => true},
    sort_by: {default: ''},
    sort_desc: {default: false},
  },
  mixins: [tableMixin],

  computed: {
    csv_global_export_enabled: function () {
      return mj_config.csv_global_export_enabled();
    },
    //override modelName on tableMixin
    // modelName: function () {
    //   return 'modelname';
    // },
    newItemHandler: function () {

      return this.customNewItem ? this.customNewItem : this.newItem;
    },
    clickRowHandler: function () {
      return this.customEditItem ? this.customEditItem : this.editItem;
    },
  },

  data: () => ({


    /*definisci header se vuoi fare un overload*/
    // headers: [
    //   { text: "Id", align: "start", value: "id" },
    // ]
  }),
  methods: {
    deleteSelectedItem: function (item) {

      if (this.customDeleteItems && this.customDeleteItems.length != 0) {

        return this.customDeleteItems(item);
      } else {
        return tableMixin.methods.deleteSelectedItem.call(this, item);
      }
    }
  },
};
</script>

