<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Product Category {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Cod'
                v-model='form.cod.val'
                :rules="[rules.required,rules.max(6)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='10'>
            <v-text-field
                label='Titolo'
                v-model='form.title.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12'>
            <v-textarea
                label='Descrizione'
                v-model='form.description.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
          <v-col cols='12'>
            <v-switch
                label='Il più venduto'
                :true-value="1"
                :false-value="0"
                v-model="form.best_seller.val"
            >
            </v-switch>
          </v-col>
        </v-row>

        <v-row align="end">
          <v-col cols='12' md='6'>
            <MjTextField
                label='Eta Minima'
                v-model='form.minimum_age.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='0' max_significant_digits='21'
                locale='it-IT'
            >
            </MjTextField>
          </v-col>

          <v-col cols='12' md='6'>
            <v-textarea
                label='Nota Tecnica'
                hint="Dati riguardanti il tipo di mezzo messo a disposizione o altra nota tecnica.."
                v-model='form.technical_note.val'
                rows="1">

            </v-textarea>
          </v-col>
        </v-row>

        <v-row align="end">
          <v-col cols='12' md='6'>
            <div class="caption">Livello Difficoltà</div>
            <v-radio-group v-model="form.difficulty_level.val" row hint="Valore utilizzato per indicare con un numero di caschi il livello di difficolta da 1 a 5" persistent-hint>
              <v-radio :value="1" label="1">1</v-radio>
              <v-radio :value="2" label="2">2</v-radio>
              <v-radio :value="3" label="3">3</v-radio>
              <v-radio :value="4" label="4">4</v-radio>
              <v-radio :value="5" label="5">5</v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols='12' md='6'>
            <v-text-field
                label='Descrizione Difficoltà'
                hint="Testo per indicare la difficoltà in modo descrittivo, ad esempio 'Facile' o 'Media'"
                v-model='form.difficulty_description.val'>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <mj-image
              ref="category_image"
              :image-src="form.image_base64.val"
              @img-changed="updateImage"
              shape="rectangle"
              width="100%"
              :height="240"
          >
          </mj-image>
        </v-row>
        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjTextField from "@/components/form/MjTextField.vue";
import MjImage from "@/components/form/MjImage.vue";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";


export default {
  name: "SchedaProductCategory",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar,MjTextField,MjImage},
  data: () => ({

    form: {

      cod: {type: 'string', val: '', default_val: ''},
      title: {type: 'string', val: '', default_val: ''},
      description: {type: 'text', val: '', default_val: ''},
      best_seller: {type: 'integer', val: '', default_val: ''},
      minimum_age: {type: 'integer', val: '', default_val: ''},
      difficulty_description: {type: 'string', val: ''},
      difficulty_level: {type: 'integer', val: 5, default_val:5},
      technical_note: {type: 'string', val: ''},
      image_base64:{type: 'string', val: '', default_val: ''},
    },
    apiUrl: "/api/product_categories"
  }),

  methods: {
    updateImage(image){
      this.form.image_base64.val = image;
    },
    newItem(){
      this.$refs.category_image.resetImage();
      schedaMixin.methods.newItem.call(this);
    }
  }
};
</script>
