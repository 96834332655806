/*
*
* tramite le prop puoi passare un price per indicare il prezzo (tieni conto che questo flusso vale ad esempio per fare dei pagamenti in cui l'utente sceglie l'importo: es. delle ricariche)
* oppure l'id dell'item (purchasing_item_id) da acquistare (in questo caso il price viene ignorato)
* Insieme al purchasing_item_id si può aggiungere il purchase_additional_data che trasporta informazioni aggiuntivi sull'acquisto (ad esempio le note)
*
* se nel dialog vuoi mostrare il prezzo da pagare devi utilizzare gli slot.
*
* Dialog per gestire il pagamento tramite stripe.
* Il flusso prevede
* - Creazione dell'oggetto stripe, elements e card sul mounted che saranno attaccatti al div predisposto
* - Agganciare il listener sulla stripe card per gestire gli errori di validazione e abilitare il pulsante confirm
* - ottenere tramite getStripePaymentIntent un intenzione di pagamento dal server.
* - in fase di click su confirm confermare lato client il pagamento mediante api stripe
* - se il risultato è ok, passarlo al server che lo verifica e procede con incremento credito, email o altro.
*
* Il componente genera due eventi
* payment-cancel e payment-done
* catturando tali elementi si decide se mostrare o meno il dialog (lo gestisce il parent)
*
* In caso di riutilizzo del componente il mounted non viene richiamato (quindi la card e gli elements vengono "riciclati")
* una watch sul dialog_show invece in caso di dialog_show==true richiede un nuovo payment intent.
*
*
* Usa lo slot per compilare la parte testuale e di istruzioni prima dell'inserimento carta.
*/
<template>
  <div class="text-center">
    <v-dialog v-model="dialog_show" width="500" :fullscreen="fullscreen">
      <v-progress-linear indeterminate v-show="loading > 0"></v-progress-linear>
      <v-card>
        <v-card-title class="headline" primary-title>{{dialog_title }}</v-card-title>

        <v-card-text class="pt-4 pb-4">

          <slot></slot>

          <div id="card-element" class="mt-6 mb-6" @change="this.error_text = null">

          </div>
          <v-alert type="warning"  v-show="error_text && error_text.length > 0" dense >{{error_text}}</v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              v-if="cancel_button_visible"
              color="default"
              text
              @click="$emit('payment-cancel')"
          >{{ cancel_button_text }}
          </v-btn>
          <v-btn
              v-if="confirm_button_visible"
              color="primary"
              text
              @click="stripeConfirm();"
              :disabled="confirm_button_disabled"
          >{{ confirm_button_text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import mj_config from "@/mj_config";
import mj_axios from "@/mj_axios.js";

export default {
  name: "MjStripeDialog",
  props: {
    value: {},
    dialog_title: {default: "Informazione"},
    confirm_button_text: {default: "Conferma"},
    cancel_button_text: {default: "Annulla"},
    confirm_button_visible: {default: true},
    cancel_button_visible: {default: true},
    fullscreen: {default: false},
    price: {},
    charge_type:{default:()=>null},
    purchasing_item_id:{},
    purchase_item_quantity:{},
    purchase_additional_data:{},
  },

  data() {
    return {
      dialog_show: this.value,
      stripe: null,
      stripe_elements: null,
      stripe_card: null,
      stripe_payment_intent: null,
      loading: 0,
      error_text: null,
      confirm_button_disabled: true,
    };
  },
  methods: {
    async initStripeCard(){
      this.stripe = await loadStripe(mj_config.stripePublicKey());
      this.stripe_elements = this.stripe.elements();

      const text_color = mj_config.dark_mode_on() ? '#ffffff' : '#000000';
      const icon_color = mj_config.dark_mode_on() ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary;

      this.stripe_card = this.stripe_elements.create('card', {
        style: {
          base: {
            iconColor: icon_color,
            color: text_color,
          },
        }
      });
      this.stripe_card.mount("#card-element");

      this.stripe_card.addEventListener('change', (event) => {
        if (event.complete) {
          this.error_text = null;
          // enable payment button
          this.confirm_button_disabled = false;
        } else if (event.error) {

          this.error_text = event.error.message;
          this.confirm_button_disabled = true;
          // show validation to customer
        }
      });
    },
    //il server crea un payment intent
    getStripePaymentIntent() {
      this.loading++;
      const params = {amount: this.price, quantity: this.purchase_item_quantity, purchasing_item_id: this.purchasing_item_id, charge_type: this.charge_type, purchase_additional_data: this.purchase_additional_data}
      const axios_promise = mj_axios.get('/api/stripe/create_payment_intent', {params: params});
      axios_promise.then(response => {
        this.stripe_payment_intent = response.data;
      }).catch((e) => {
      const message =e.response.data.message
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di setup del pagamento ,${message},se il problema persiste contattare l'assistenza tecnica.`,
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    },


    stripeConfirm() {

      if (this.stripe_payment_intent) {
        this.loading++;
        this.stripe.confirmCardPayment(this.stripe_payment_intent.client_secret, {
          payment_method: {
            card: this.stripe_card,
            // billing_details: {
            //   name: 'Nome di prova'
            // }
          },
          receipt_email: this.$store.state.token_obj.email,
          setup_future_usage: 'on_session'
        }).then((result) => {
          // Access the token with result.token

          this.loading--;
          if (result['error']){

           this.error_text = result.error.message;

          }
          else {
            this.loading++;
            const axios_promise = mj_axios.post('/api/stripe/process_confirmed_payment_intent', result);
            axios_promise.then(() => {

              this.$emit('payment-done');

            }).catch(() => {
              this.$store.commit("update_modal_dialog", {
                title: "Attenzione!!",
                text: "Errore di finalizzazione del pagamento, se l'errore persiste contattare l'assistenza tecnica.",
                visible: true,
              });
            }).then(() => {
              this.loading--;
            });

          }
        }).catch(() => {
          this.loading--;

          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            // text: `Errore di finalizzazione del pagamento,${result.error.message} ,se l'errore persiste contattare l'assistenza tecnica.`,
            text: `Errore di finalizzazione del pagamento,{result.error.message} ,se l'errore persiste contattare l'assistenza tecnica.`,
            visible: true,
          });

        });
      }
    }
  },
  watch: {
    value: function (val) {

      this.dialog_show = val;
    },
    dialog_show: function(val){

      if (val)
      {
        this.initStripeCard();
        this.getStripePaymentIntent();
      }
    }
  },
};
</script>