/*
Mostra il credito dell'utente appongiandosi sul dato presente nello $store.
La valuta di default è €, ma tramite prop si può passare una valuta differente (ad esempio credito virtuale yums)

Mediante la prop showHistory decidiamo se mostare o meno la cronologia delle ricariche.
la prop amounts indica i tagli di ricarica validi (ricorda che i valori sono espressi in centesimi)


*/
<template>
  <v-container>


    <v-card>
      <v-card-actions class="justify-end mb-0 pb-0">
        <v-btn icon @click="updateCredit">
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-subtitle class="text-center">
        Il tuo credito attuale è
      </v-card-subtitle>
      <v-card-title class="justify-center display-3">
        {{ credit  }} <span>{{ currency }}</span>
      </v-card-title>
      <h1 class="rounded text-center primary display-3 "></h1>
      <v-card-actions class="justify-center ">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
                   v-on="on" class="mb-4" color="primary" left>Aggiungi Credito
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(amount, index) in amounts"
                :key="index"
                link
            >
              <v-list-item-content>
                <v-list-item-title @click="price = amount;show_stripe_dialog = true" v-text="`+ ${amount/100}€`">
                </v-list-item-title>
              </v-list-item-content>

            </v-list-item>
          </v-list>

        </v-menu>
      </v-card-actions>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
    <v-card v-if="show_history" class="mt-4">
      <v-progress-linear indeterminate v-show="loading>0"></v-progress-linear>
      <v-card-title>Cronologia ricariche
        <v-spacer></v-spacer>
        <v-btn icon @click="loadPaymentLog">
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item v-for="pl in payment_log" :key="pl.id" two-line>
            <v-list-item-avatar>
              <v-icon :color="pl.status === 'succeeded' ? 'green' : 'red' ">mdi-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ pl.amount / 100 }}{{ currency }}</v-list-item-title>
              <v-list-item-subtitle>{{ pl.created_at }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>
    <MjStripeDialog v-model="show_stripe_dialog" :price="price" confirm_button_text="Ricarica"
                    @payment-cancel="show_stripe_dialog = false"
                    charge_type="RECHARGE"
                    @payment-done="show_stripe_dialog = false; updateCredit();loadPaymentLog()"></MjStripeDialog>
  </v-container>
</template>

<style>

</style>
<script>

import mj_axios from "@/mj_axios.js"
import MjStripeDialog from "@/components/payment/MjStripeDialog";

export default {
  name: "MjUserCredit",
  components: {MjStripeDialog},
  props: {
    currency: {default: () => '€'},
    show_history: {type: Boolean, default: () => true},
    amounts: {type: Array, default: () => [1000, 3000, 5000, 10000]},

  },
  computed: {
    credit: function () {
      return this.$store.state.token_obj.credit;
    },
  },
  mounted() {
    if (this.show_history) {
      this.loadPaymentLog();
    }
    this.updateCredit();
  },
  data() {
    return {
      payment_log: null,
      loading: 0,
      show_stripe_dialog: false,
      price: 10,
    };
  },
  methods: {
    updateCredit: function () {
      this.$store.dispatch('reloadLoggedUser');
    },
    loadPaymentLog() {
      this.loading++;
      const axios_promise = mj_axios.get('/api/stripe/recharge_log');
      axios_promise.then(response => {
        this.payment_log = response.data.data;


      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento della cronologia pagamenti, se il problema persiste contatta l'assistenza tecnica.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    }
  },
  watch: {

    show_history(val) {
      if (val) this.loadPaymentLog();
    }
  },
};
</script>