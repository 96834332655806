/* 
SCOPO DEL COMPONENTE: Permettere il filtraggio basato su intervallo di date dal al

la prop mode può essere

date
time
datetime

su emitchange vengono inviati i dati secondo il formato json necessario al b.e. (ListController)
(le due date vengono splittate in due filtri)
*/

<template>
  <v-container>
    <div v-if="!!label">{{ label }}</div>

    <v-row align="start">
      <v-col md="6">
        <MjDateTimePicker
          v-if="mode === 'datetime'"
          :label="labelFrom"
          v-model="valueFromModel"
          :date_model_format="dateModelFormat"
          :date_display_format="dateDisplayFormat"
          :time_model_format="timeModelFormat"
          :time_display_format="timeDisplayFormat"
          @input="emitChange"
        />
        <MjDateOrTimePicker
          v-if="mode !== 'datetime'"
          :label="labelFrom"
          v-model="valueFromModel"
          :mode="mode"
          :model_format="mode === 'date' ? dateModelFormat : timeModelFormat"
          :display_format="
            mode === 'date' ? dateDisplayFormat : timeDisplayFormat
          "
          @input="emitChange"
        />
      </v-col>
      <v-col md="6">
        <MjDateTimePicker
          v-if="mode === 'datetime'"
          :label="labelTo"
          v-model="valueToModel"
          :date_model_format="dateModelFormat"
          :date_display_format="dateDisplayFormat"
          :time_model_format="timeModelFormat"
          :time_display_format="timeDisplayFormat"
          @input="emitChange"
        />
        <MjDateOrTimePicker
          v-if="mode !== 'datetime'"
          :label="labelTo"
          v-model="valueToModel"
          :mode="mode"
          :model_format="mode === 'date' ? dateModelFormat : timeModelFormat"
          :display_format="
            mode === 'date' ? dateDisplayFormat : timeDisplayFormat
          "
          @input="emitChange"
        />
      </v-col>
      <v-col cols="1" class="mr-5">
        <v-btn
          fab
          small
          :class="glue_data === 'AND' ? 'accent caption' : 'primary caption'"
          @click="
            glue_data === 'OR' ? (glue_data = 'AND') : (glue_data = 'OR');
            emitChange();
          "
          v-if="glue_data"
          >{{ glue_data }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <div
        class="warning--text v-messages"
        :v-show="validate_response != null && validate_response.message != null"
      >
        {{
          validate_response != null && validate_response.message != null
            ? validate_response.message
            : ""
        }}
      </div>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
import MjDateTimePicker from "@/components/form/MjDateTimePicker.vue";
import MjDateOrTimePicker from "@/components/form/MjDateOrTimePicker.vue";

export default {
  name: "MjDateIntervalFilter",
  components: { MjDateTimePicker, MjDateOrTimePicker },
  props: {
    label: null,
    labelFrom: {},
    labelTo: {},
    valueFrom: {},
    valueTo: {},
    mode: {},
    dateDisplayFormat: {},
    timeDisplayFormat: {},
    dateModelFormat: {},
    timeModelFormat: {},

    filter_name: {},
    glue: { type: String },
    silent_reset: {}, //usata nel watch per capire se il parent chiede un reset
  },
  computed: {},

  data: function () {
    return {
      glue_data: this.glue ? this.glue.toUpperCase() : this.glue,
      valueToModel: this.valueTo,
      valueFromModel: this.valueFrom,
      validate_response: null,
    };
  },
  created() {
    // this.validate();
    this.emitChange();
  },
  methods: {
    emitChange: function () {
      const validate_response = this.validate();
      if (validate_response.isValid) {
        const filter_data_array = [];

        let data_from_val = null;
        //data_from_val sarà null o un array con il valore
        if (this.valueFromModel !== "" && this.valueFromModel != null) {
          //se non è datetime di default dal avrà ora 00:00
          if (this.mode === "datetime"){ 
            data_from_val = [this.valueFromModel];
          }
          else {
            data_from_val = [this.valueFromModel + " 00:00:00"];
          }
        }

        // if (this.validate_response.from_model_moment && this.validate_response.from_model_moment.isValid()) {
        const data_from = {
          name: this.filter_name,
          type: "gte",
          val: data_from_val,
          glue: this.glue_data ? this.glue_data.toLowerCase() : this.glue_data,
        };

        filter_data_array.push(data_from);
        // }

        // if (this.validate_response.to_model_moment && this.validate_response.to_model_moment.isValid()) {

        //la glue nel caso in cui è valida solo una delle due date la prendiamo dal this.glue_data (ovvero l'input dell'utente)
        //se invece sono entrambi validi i valori (from e to) tra from e to dobbiamo forzare una glue AND.
        //forzo il precedente filtro con glue = and
        if (
          this.validate_response.from_model_moment &&
          this.validate_response.from_model_moment.isValid() &&
          this.validate_response.to_model_moment &&
          this.validate_response.to_model_moment.isValid()
        ) {
          filter_data_array[0].glue = "and";
        }

        let data_to_val = null;
        //data_from_val sarà null o un array con il valore
        if (this.valueToModel !== "" && this.valueToModel != null) {
          //se non è datetime di default dal avrà ora 23:59:59
          if (this.mode === "datetime"){ 
            data_to_val = [this.valueToModel];
          }
          else {
            data_to_val = [this.valueToModel + " 23:59:59"];
          }
        }

        const data_to = {
          name: this.filter_name,
          type: "lte",
          val:data_to_val,
          glue: this.glue_data ? this.glue_data.toLowerCase() : this.glue_data,
        };

        filter_data_array.push(data_to);
        // }

        // if (filter_data_array.length > 0) {
        this.$emit("change", filter_data_array);
        // }
      }
    },

    validate: function () {
      this.validate_response = {};
      this.validate_response.isValid = true;
      this.validate_response.message = "";
      this.validate_response.from_model_moment = null;
      this.validate_response.to_model_moment = null;

      let format = "";
      if (this.mode === "datetime") {
        format = this.dateModelFormat + " " + this.timeModelFormat;
      }

      let from = null;
      let to = null;
      if (this.valueFromModel != null && this.valueFromModel.length > 0) {
        from = moment(this.valueFromModel, format, true);
        this.validate_response.from_model_moment = from;
      }
      if (this.valueToModel != null && this.valueToModel.length > 0) {
        to = moment(this.valueToModel, format, true);
        this.validate_response.to_model_moment = to;
      }

      if (from != null && to != null && from.isValid() && to.isValid()) {
        if (from.isValid() && to.isValid() && from.isAfter(to)) {
          this.validate_response.message =
            "La data di inizio intervallo è maggiore di quella di fine";
          this.validate_response.isValid = false;
        }
      }
      return this.validate_response;
    },
  },
  watch: {
    silent_reset: function () {

      this.valueFromModel = null;
      this.valueToModel = null;
      this.validate_response = {};
    },
  },
};
</script>