/*
Utilizza il componente GoogleLogin (yarn install vue-google-login)

Nei data devi impostare il Google Client Id.
I callback dei GoogleLogin (componente esterno) sono gestiti da questo componente.

Nel caso di failure viene mostrata una snackbar
Nel caso di success viene chiamato l'apposito controller nel b.e. /api/user/google/verify_token_and_login/
Passandogli l'access token ottenuto.

Il b.e. in caso positivo risponde con un tokenobject uguale a quello dell'operazione standard di login (email e pwd)
che starà gestito dallo store vuex.

NOTA su stato caricamento.

Il vue-google-login consente di renderizzare il bottone o meno.
Nel secondo caso di è liberi di scegliere la grafica, ma onde evitare di andare contro le regole di google di styling del brand si usa la renderizzazione di default.
Per capire quando il google button è disponibile si utilizza il plugin "Loader" e sul mounted Vue.GoogleAuth.
Quindi prima che carichi viene mostrato un button "fake", lo stesso viene utilizzato per indicare caricamento del verify token verso il b.e..

*/
<template>
  <v-container>
    <v-row align="center" justify="center">
      <GoogleLogin
          v-show="!showLoader"
          :params="googleSignInParams"
          :renderParams="googleSignInRenderParams"
          :onSuccess="onGoogleLoginSuccess"
          :onFailure="onGoogleLoginFailure">


      </GoogleLogin>
      <!-- btn mostrato solo durante il loading in quanto quello renderizzato da google non è possibile mostrare il loader all'interno-->
      <v-btn class="g-signin-button" v-show="showLoader" :loading="showLoader">
        <v-icon left>mdi-google</v-icon>
        Google Login
        <template v-slot:loader>
          <v-icon left class="mr-1">mdi-google</v-icon>
          <span>Caricamento...</span>
        </template>
      </v-btn>


      <v-snackbar
          app
          v-model="snackbar_show"
          type="warning"
          width="100%"
          color="info"
          vertical="vertical"
      >{{ snackbar_text }}
      </v-snackbar>
    </v-row>
  </v-container>
</template>
<style>
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  padding: 4px 8px;
  border-radius: 4px;
  min-width: 190px; /*in modo da essere uguale a quello di fb*/
}


</style>

<script>
import mj_axios from "@/mj_axios.js";
import store from "@/store.js";
import mj_config from "@/mj_config.js";
import GoogleLogin from "vue-google-login";
import Vue from 'vue'
import {LoaderPlugin} from 'vue-google-login';

Vue.use(LoaderPlugin, {
  client_id: mj_config.googleClientId()
});

export default {
  name: "MjGoogleLoginButton",

  components: {GoogleLogin},

  created() {
    this.showLoader = true;
    this.googleSignInRenderParams.theme = this.$vuetify.theme.dark ? 'dark' : 'light';


    Vue.GoogleAuth.then(() => {
      this.showLoader = false;
      // console.log(auth2.isSignedIn.get());
      // console.log(auth2.currentUser.get())
    });
  },
  data: () => ({
    snackbar_show: false,
    snackbar_text: '',
    googleSignInParams: {
      clientId:
          mj_config.googleClientId(),
    },
    googleSignInRenderParams: {
      width: 190,
      height: 36,
      longtitle: false,

    },
    showLoader: false,
  }),
  methods: {
    onGoogleLoginSuccess(googleUser) {
      this.showLoader = true;
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      // const profile = googleUser.getBasicProfile(); // etc etc

      mj_axios
          .get(
              "/api/user/google/verify_token_and_login/" + googleUser.getAuthResponse(true).access_token
          )
          .then((response) => {
            const token_obj = response.data;
            store.commit("login_success", token_obj);
            this.$router.replace("/menu");
          })
          .catch((error) => {

            if (mj_config.allow_registration() && (error != null && error.response != null && error.response.status === 401)) {
              const query_string = (error.response.data.email) ? `?email=${error.response.data.email}` : '';
              this.$router.push(`/utente/registrati${query_string}`);
            } else {
              this.snackbar_show = true;
              const message = (error.response.data.message) ? error.response.data.message : '';
              this.snackbar_text = `Errore Nel Google Login ${message}`;
            }
          })
          .then(() => {
            this.showLoader = false;
          });
    },
    onGoogleLoginFailure(error) {
      // alert(error);
      this.snackbar_show = true;
      this.snackbar_text = `Errore Nel Google Login ${error}`;
      this.showLoader = false;
    },
  },
};
</script>
