<template>
  <v-container fill-height>
    <v-card class="mx-auto text-center" width="75%" justify="center">
      <v-card-text>
        <p class="display-1 text--primary">Ops! Qualcosa è andato Storto</p>
        <p>{{message}}</p>
        <v-btn class="ma-2" color="success" to="/">
          Torna alla Home
          <v-icon dark right>mdi-home</v-icon>
        </v-btn>
        <div class="caption mt-4">
              Per ogni esigenza non esitare a contattare l'assistenza tecnica 
              <a :href='mail_assistenza_link'>{{mail_assistenza}}</a>
            </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      message: this.$route.query.msg || "",
    };
  },
  computed:{
    mail_assistenza: function(){return this.$store.state.client_settings.MAIL_ASSISTENZA},
    mail_assistenza_link:function(){return 'mailto:'+this.mail_assistenza;}
  }
};
</script>
