<template>
  <v-container>
    <!--VCALENDAR TOOLBAR-->
    <v-row dense elevation="0" color="transparent" class="mx-0 mb-2 px-0">
      <v-btn
          icon
          small
          class="ml-3"
          @click="vCalendarShift('l')"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h4 class="text-center grow">
        Calendario Eventi
      </h4>

      <v-btn
          icon
          small
          class="ml-2 mr-0"
          @click="vCalendarShift('r')"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>


    </v-row>
    <!--VCALENDAR-->
    <v-sheet height="480">
      <v-progress-linear v-show="calendar_loader > 0" indeterminate></v-progress-linear>
      <v-calendar locale="it"
                  :events="eventsIntervalSetup"
                  ref="calendar"
                  color="primary"
                  :event-timed="()=>true"
                  :type="calendar_view_mode"

                  v-model="calendar_model"
                  :start="vcalendar_start"
                  @change="changeVCalendar"
                  event-overlap-mode="column"
:short-months="false"
                  @click:date="changeCalendarViewMode"
                  @click:event="showEventPopup"
      >
        <template v-slot:event="{ event, /*timed ,eventSummary*/ }">
          <div class='event_cell' >
              <h4 class="font-weight-bold pl-1 ">{{ event.title }}</h4>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
    <!--    SNACKBAR-->
    <v-snackbar v-model="snackbar_visible" color="warning" right timeout="6000">
      <v-icon>mdi-information-outline</v-icon>
      {{ snackbar_text }}
    </v-snackbar>

    <v-dialog  width="400" v-model="showEventDialog" v-if="selected_event!=null">
      <v-card>
        <v-card-title class="primary--text">{{selected_event.event.title}}<v-spacer></v-spacer><v-btn icon @click="showEventDialog = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-subtitle class="font-weight-bold">Da: {{moment(selected_event.event.start).format('YYYY-MM-DD HH:mm')}}
          <br>
          A: {{moment(selected_event.event.end).format('YYYY-MM-DD HH:mm')}}</v-card-subtitle>
        <v-card-text>{{selected_event.event.description}}</v-card-text>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.event_cell {

  background-color: #2dafb3;
  height: 100%;

}
</style>
<script>

import moment from "moment";
import mj_axios from "@/mj_axios.js";

export default {
  name: "EventsCalendarView",
  components: {},


  computed: {},
  data: () => ({
    eventsIntervalSetup: [],
    calendar_loader: 0,
    calendar_model: null,
    snackbar_visible: false,
    vcalendar_start: moment().format('yyyy-MM-DD'),
    snackbar_text: '',
    calendar_from: null,
    calendar_to: null,
    calendar_view_mode:'month',
    selected_event: null,
    showEventDialog: false,

  }),

  methods: {
//per uso nei tag del template
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },
//serve a muovere le settimane o cmq il v-calendar avanti e indietro nel tempo
    vCalendarShift(direction) {
      if (direction === 'l') {

        const now = moment();
        //quando faccio lo shift il calendar_from e to sono ancora quelli prima dell'avvenuto shift (infatti saranno aggiornati al calendar change)
        //quindi se now si trova dentro from-to vuol dire che stò facendo un calendar.prev dalla settimana corrente verso sinistra (indietro) => VIETATO => SNACKBAR!
        if (now.isBetween(moment(this.calendar_from), moment(this.calendar_to))) {
          this.snackbar_text = "Non puoi visionare il calendario per le settimane precedenti";
          this.snackbar_visible = true;
        } else {

          this.$refs.calendar.prev();
        }

      } else if (direction === 'r') {
        this.$refs.calendar.next();
      }

    },
    //evento di onChange del v-calendar
    changeVCalendar(changeEvt) {

      this.calendar_from = changeEvt.start.date + " 00:00:00";
      //in mysql non esiste 24.00 quindi devo calcolare il giorno successivo
      this.calendar_to = changeEvt.end.date + " 23:59:59";

      this.loadEventsIntervalSetup();
    },
    async loadEventsIntervalSetup() {

      this.calendar_loader++;


      const params = {
        "calendar_type": this.selected_calendar_type,
        "calendar_from": this.calendar_from,
        "calendar_to": this.calendar_to
      };


      const axios_promise = mj_axios.get('/api/utente/event_interval_setup_events', {params: params});
      axios_promise.then(response => {
        this.eventsIntervalSetup = response.data.data;
      }).catch(() => {
        this.eventsIntervalSetup = [];
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento delle configurazioni settimana tipo / agenda, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.calendar_loader--;
      });
      return axios_promise;


    },
    //Cambia la tipologia di visualizzazione day, week etc.
     changeCalendarViewMode(evt) {



      if (this.calendar_view_mode === 'month') {
        this.calendar_view_mode = 'day';
        this.calendar_model = this.vcalendar_start = evt.date;

      } else {
        this.calendar_view_mode = 'month';
      }

      //implementazione con rotazione anche su category day mode.
      // if (this.calendar_view_mode === 'week') {
      //   this.calendar_view_mode = 'category';
      // } else if (this.calendar_view_mode === 'category') {
      //   this.calendar_view_mode = 'day';
      // } else if (this.calendar_view_mode === 'day') {
      //   this.calendar_view_mode = 'week';
      // }

    },
    showEventPopup(event){

      this.selected_event = event;
      this.showEventDialog = true;
    }
  },
  watch: {},
};
</script>
