// import TestView from './views/TestView.vue';
import MyAccountView from './views/Custom/User/MyAccountView.vue'
import MyCreditView from './views/Custom/User/MyCreditView.vue'

import BookingView from './views/Custom/User/BookingView.vue'
import WeeklyConfigView from './views/Custom/Admin/WeeklyConfigView.vue'
import BookingHistoryAdminView from './views/Custom/Admin/BookingHistoryView.vue'
import BookingHistoryUserView from './views/Custom/User/BookingHistoryView.vue'
import EventsCalendarView from './views/Custom/User/EventsCalendarView.vue'

import SchedaProductInfo from "./views/Custom/Admin/SchedaProductInfo.vue";
import SchedaProductCategory from "./views/Custom/Admin/SchedaProductCategory.vue";
import SchedaProductSessionType from "./views/Custom/Admin/SchedaProductSessionType.vue";
import SchedaProductItem from "./views/Custom/Admin/SchedaProductItem.vue";
import SchedaBooking from "./views/Custom/Admin/SchedaBooking.vue";

export  const custom_routes = [
    { path: '/utente/il_mio_profilo', name:'MyAccountView', component: MyAccountView, meta: { title: 'Il mio Profilo', role: ['SuperAdmin','Admin','Utente'] } },
    { path: '/utente/noleggio/kart/:step?', name:'BookingNoleggioKartView', component: BookingView, meta: { title: 'Noleggio Kart', product_cod:'NK', role: ['Utente'] } },
    { path: '/utente/noleggio/moto/:step?', name:'BookingNoleggioMotoView', component: BookingView, meta: { title: 'Noleggio Moto', product_cod:'NM', role: ['Utente'] } },
    { path: '/utente/privati/kart/:step?', name:'BookingPrivatiKartView', component: BookingView, meta: { title: 'Noleggio Privati Kart', product_cod:'PR', role: ['Utente'] } },
    { path: '/utente/privati/moto/:step?', name:'BookingPrivatiMotoView', component: BookingView, meta: { title: 'Noleggio Privati Moto', product_cod:'PR', role: ['Utente'] } },
    { path: '/utente/iscrizione_competizione/:step?', name:'BookingCompetizioneView', component: BookingView, meta: { title: 'Iscrizione Competizione', product_cod:'CO', role: ['Utente'] } },
    { path: '/utente/il_mio_credito', name:'MyCreditView', component: MyCreditView, meta: { title: 'Il mio Credito', role: ['Utente'] } },

    { path: '/admin/prenotazioni', name:'BookingHistory', component: BookingHistoryAdminView, meta: { title: 'Prenotazioni', role: ['SuperAdmin','Admin'] } },
    { path: '/utente/prenotazioni', name:'BookingHistory', component: BookingHistoryUserView, meta: { title: 'Prenotazioni', role: ['Utente'] } },
    { path: '/calendario_eventi', name:'EventsCalendarView', component: EventsCalendarView, meta: { title: 'Calendario Eventi', role: ['Public'] } },

    //super admin
    { path: '/admin/weekly_config', name:'WeeklyConfigView', component: WeeklyConfigView, meta: { title: 'Configurazione Settimanale',  role: ['Admin','SuperAdmin'] } },

    { path: '/scheda_product_info', name: 'NewSchedaProductInfo', component: SchedaProductInfo, meta: { title: 'Scheda Product Info', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_product_info/:id', name: 'SchedaProductInfo', component: SchedaProductInfo, meta: { title: 'Scheda Product Info', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_product_category', name: 'NewSchedaProductCategory', component: SchedaProductCategory, meta: { title: 'Scheda Product Category', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_product_category/:id', name: 'SchedaProductCategory', component: SchedaProductCategory, meta: { title: 'Scheda Product Category', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_product_session_type', name: 'NewSchedaProductSessionType', component: SchedaProductSessionType, meta: { title: 'Scheda Product Session Type', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_product_session_type/:id', name: 'SchedaProductSessionType', component: SchedaProductSessionType, meta: { title: 'Scheda Product Session Type', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_product_item', name: 'NewSchedaProductItem', component: SchedaProductItem, meta: { title: 'Scheda Product Item', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_product_item/:id', name: 'SchedaProductItem', component: SchedaProductItem, meta: { title: 'Scheda Product Item', role: ['SuperAdmin','Admin'], header: 'close' } },

    { path: '/scheda_booking', name: 'NewSchedaBooking', component: SchedaBooking, meta: { title: 'Scheda Booking', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_booking/:id', name: 'SchedaBooking', component: SchedaBooking, meta: { title: 'Scheda Booking', role: ['SuperAdmin','Admin'], header: 'close' } },



];
