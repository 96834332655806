<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Product Item {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Tipo Prodotto'
                v-model='form.product_infos_id.val'
                item-text="title" item-value="id" :items="this.selects.product_infos_id" cache-items
                :rules="[rules.required]"

            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Categoria Prodotto'
                v-model='form.product_categories_id.val'
                item-text="title" item-value="id" :items="this.selects.product_categories_id" cache-items
                clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Tipo Sessione'
                v-model='form.product_session_types_id.val'
                item-text="title" item-value="id" :items="this.selects.product_session_types_id" cache-items
                clearable
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='3'>
            <MjTextField
                label='Prezzo'
                hint="Prezzo di listino del prodotto"
                persistent_hint
                v-model='form.price.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='0' max_significant_digits='10'
                type="money"
                locale='it-IT'
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='3'>
            <MjTextField
                label='Sconto'
                hint="Sconto sul prezzo di listino valido per tutti"
                persistent_hint
                v-model='form.discount.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='0' max_significant_digits='10'
                locale='it-IT'
                type="percent"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='3'>
            <MjTextField
                label='Sconto per Prepagato'
                hint="Sconto sul prezzo di listino valido solo utilizzando il prepagato"
                persistent_hint
                v-model='form.discount_prepaid.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='0' max_significant_digits='10'
                locale='it-IT'
                type="percent"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='3'>
            <MjTextField
                label='Max. Prenotabili'
                persistent-hint
                hint="Numero massimo di persone che possono prenotare il prodotto"
                v-model='form.max_bookable.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='0' max_significant_digits='21'
                locale='it-IT'
            >
            </MjTextField>
          </v-col>
        </v-row>



        <v-sheet max-width="300px" color="transparent" class="mt-2">
          <v-btn-toggle v-model="form.accepted_payment_type_ids.val" multiple label="Modalità di Pagamento Accettate"
                        active-class="primary" dense>
            <v-tooltip top v-for="payment_type in payment_types" :key="payment_type.id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :value="payment_type.id" icon>
                  <v-icon>{{ payment_type.icon }}</v-icon>
                </v-btn>
              </template>
              Modalità Pagamento Accettata: <br><b>{{ payment_type.description }}</b>
            </v-tooltip>
          </v-btn-toggle>
          <v-progress-linear class="mt-2 px-0 mx-0" v-show="loader_payment_type" indeterminate></v-progress-linear>
          <div class="caption " style="opacity:0.8">Tipologie di Pagamento Accettate</div>
        </v-sheet>


        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjTextField from '@/components/form/MjTextField.vue';
import mj_axios from "@/mj_axios";


export default {
  name: "SchedaProductItem",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar, MjTextField},
  beforeMount() {
    this.loadPaymentTypes();
  },
  data: () => ({
    payment_types: [],
    loader_payment_type: false,
    form: {
      product_infos_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/product_infos'},
      product_categories_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/product_categories'
      },
      product_session_types_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/product_session_types'
      },
      price: {type: 'decimal', val: '', default_val: ''},
      discount: {type: 'decimal', val: '', default_val: ''},
      discount_prepaid: {type: 'decimal', val: '', default_val: ''},
      max_bookable: {type: 'integer', val: '', default_val: ''},
      accepted_payment_type_ids: {type: 'array', val: ''},
    },
    apiUrl: "/api/product_items"
  }),

  methods: {//-------------------------TIPOLOGIE PAGAMENTO-----------------
    loadPaymentTypes() {
      this.loader_payment_type = true;
      const axios_promise = mj_axios.get('/api/payment_type');
      axios_promise.then(response => {
        this.payment_types = response.data.data;
      }).catch(() => {

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento delle tipologie di pagamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loader_payment_type = false;
      });
      return axios_promise;

    },
  }
};
</script>
