<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Prenotazione {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='8'>
            <MjDateTimePicker
                label='Inizio'
                v-model='form.start.val'
                time_model_format='HH:mm' time_display_format='HH:mm' date_model_format='YYYY-MM-DD' mode='date'
                date_display_format='DD-MM-YYYY'
            >
            </MjDateTimePicker>
          </v-col>
          <v-col cols='12' md='8'>
            <MjDateTimePicker
                label='Fine'
                v-model='form.end.val'
                time_model_format='HH:mm' time_display_format='HH:mm' date_model_format='YYYY-MM-DD' mode='date'
                date_display_format='DD-MM-YYYY'
            >
            </MjDateTimePicker>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Num. Partecipanti'
                v-model='form.n_partecipants.val'
                :rules="[rules.required, rules.integer]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='5'>
            <v-text-field
                label='Nome Team'
                v-model='form.team_name.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='2'>
            <MjTextField
                label='Prezzo'
                v-model='form.price.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='10'
                locale='it-IT'
                type="money"
                hint="Prezzo al lordo dello sconto"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='2'>
            <MjTextField
                label='Amount'
                :disabled="true"
                v-model='form.amount.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='0' max_significant_digits='21'
                locale='it-IT'
                hint="Importo Addebitato da Stripe espresso in centesimi"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='2'>
            <MjTextField
                label='Sconto'
                v-model='form.discount.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='10'
                locale='it-IT'
                type="percent"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='2'>
            <MjTextField
                label='Sconto Prepag.'
                v-model='form.discount_prepaid.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='10'
                locale='it-IT'
                type="percent"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Tipo di Pagamento'
                v-model='form.selected_payment_type_id.val'
                :rules="[rules.required]"
                item-text="description" item-value="id" :items="this.selects.selected_payment_type_id" cache-items
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Descrizione prodotto'
                v-model='form.product_item_description.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Scegli Utente'
                v-model='form.users_id.val'
                :item-text="(item) => `${item.name} - ${item.email}`" item-value="id" :items="this.selects.users_id"
                cache-items
            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Email Utente'
                v-model='form.user_email.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Codice Prodotto'
                disabled
                v-model='form.product_item_cod.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Eis relocated key'
                disabled
                v-model='form.eis_relocated_key.val'
                :rules="[rules.max(128)]"
                hint="valore interno di sistema.."
                persistent-hint
            >

            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                disabled
                label='Eis original key'
                v-model='form.eis_original_key.val'
                :rules="[rules.max(128)]"
                hint="valore interno di sistema.."
                persistent-hint
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>

          <v-col cols='12' md='12'>
            <v-textarea
                rows="2"
                label='Note di Prenotazione'
                v-model='form.note.val'
                :rules="[rules.max(255)]">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row align="center" justify="start">
          <v-col cols='12' md='4' class="mr-4">
            <div class="v-label grey--text mb-2">Stato Prenotazione</div>
            <v-btn-toggle dense v-model='form.booking_status.val'>
              <v-btn small :value="1">
                <v-icon>mdi-check</v-icon>
                Inserito
              </v-btn>
              <v-btn small :value="-1">
                <v-icon>mdi-cancel</v-icon>
                Annullato
              </v-btn>
            </v-btn-toggle>

          </v-col>
          <v-col cols='12' md='4'>
            <div class="v-label grey--text mb-2">Stato Pagamento</div>
            <v-btn-toggle dense v-model='form.payment_status.val'>
              <v-btn small :value="1">
                <v-icon>mdi-currency-eur</v-icon>
                Pagato
              </v-btn>
              <v-btn small :value="0">
                <v-icon>mdi-currency-eur-off</v-icon>
                Da Pagare
              </v-btn>
            </v-btn-toggle>


          </v-col>
        </v-row>
        <v-btn v-if="this.$route.params.id" :loading="send_email_loading" depressed outlined class="mr-2 mt-4" color="secondary" @click="sendBookingConfirmationEmail">
          Invia Email Conferma
          <v-icon right>mdi-email</v-icon>
        </v-btn>
        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjDateTimePicker from '@/components/form/MjDateTimePicker.vue';
import MjTextField from '@/components/form/MjTextField.vue';
import mj_axios from "@/mj_axios";


export default {
  name: "SchedaBooking",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar, MjDateTimePicker, MjTextField},
  created() {
    schedaMixin.methods.init.call(this).then(
        this.fillFormWithQueryString);
  },
  data: () => ({
    send_email_loading: false,
    form: {
      start: {type: 'datetime', val: '', default_val: ''},
      end: {type: 'datetime', val: '', default_val: ''},
      team_name: {type: 'string', val: '', default_val: ''},
      n_partecipants: {type: 'integer', val: '', default_val: 1},
      price: {type: 'decimal', val: '', default_val: ''},
      amount: {type: 'integer', val: '', default_val: ''},
      discount: {type: 'decimal', val: '', default_val: ''},
      discount_prepaid: {type: 'decimal', val: '', default_val: ''},
      product_item_description: {type: 'text', val: '', default_val: ''},
      product_item_cod: {type: 'string', val: '', default_val: ''},
      selected_payment_type_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/payment_type'
      },
      eis_relocated_key: {type: 'string', val: '', default_val: ''},
      eis_original_key: {type: 'string', val: '', default_val: ''},
      users_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/user/users'},
      user_email: {type: 'string', val: '', default_val: ''},
      note: {type: 'string', val: '', default_val: ''},
      deleted_at: {type: 'string', val: '', default_val: ''},
      booking_status: {type: 'integer', val: '', default_val: 1},
      payment_status: {type: 'integer', val: '', default_val: ''},
    },
    apiUrl: "/api/bookings"
  }),

  methods: {

    fillFormWithQueryString: function () {
      //quando viene creata un prenotazione viene passato tramite query string
      //gli eis. L'apertura in questo caso avviene da WeeklyConfigView
      const get_params = this.$route.query;
      if (get_params) {
        if (get_params.eis_relocated_key) {
          this.form.eis_relocated_key.val = get_params.eis_relocated_key;
        }
        if (get_params.eis_original_key) {
          this.form.eis_original_key.val = get_params.eis_original_key;
        }
        if (get_params.start) {
          this.form.start.val = get_params.start;
        }
        if (get_params.end) {
          this.form.end.val = get_params.end;
        }
        if (get_params.price) {
          this.form.price.val = get_params.price;
        }
        if (get_params.discount) {
          this.form.discount.val = get_params.discount;
        }
        if (get_params.discount_prepaid) {
          this.form.discount_prepaid.val = get_params.discount_prepaid;
        }

        if (get_params.product_item_description) {
          this.form.product_item_description.val = get_params.product_item_description;
        }
        if (get_params.product_item_cod) {
          this.form.product_item_cod.val = get_params.product_item_cod;
        }
      }
    }
    ,
    sendBookingConfirmationEmail: function(){

      if (this.$route.params.id) {
        this.send_email_loading = true;
        const axios_promise = mj_axios.get(`/api/admin/send_booking_confirmation_email/${this.$route.params.id}`);
        axios_promise.then(() => {

        }).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di invio email di conferma, se l'errore persiste contatta l'assistenza tecnica.",
            visible: true,
          });
        }).then(() => {
          this.send_email_loading = false;
        });
        return axios_promise;
      }
      else{
        this.error_text = "Prima di procedere, devi salvare la prenotazione";
        this.error_show = true;
      }
    }
  },
  watch: {
    $route: {
      handler: function () {
        this.fillFormWithQueryString();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
