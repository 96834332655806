/*
COMPONENTE per importazione ed esportazione di un oggetto javascript in un file JSON.
Mostra all'utente due pulsanti, uno per l'importazione del json (serializzazione dell'oggetto), l'altro per l'esportazione.

Tramite prop puoi passare il dato da salvare per l'esportazione.
Il dato deve essere un oggetto serializzabile (JSON.stringify)

Tramite emit invece viene passato il file letto e deve essere catturato dal parent

IMPROVE:
gestire l'errore nel caso in cui il file json non è parsabile
configurare icone con aggiunta eventuali testi

*/
<template>
<v-sheet color="transparent">
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
  <v-btn icon v-bind="attrs" v-on="on" >
  <v-file-input
      class="mt-0 pt-0 pl-3"
      v-model="uploaded_file"
      accept="application/json"
      hide-input
      prepend-icon="mdi-table-arrow-up"
  ></v-file-input>
  </v-btn>
    </template>
    Importa File JSON
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
    <v-btn v-bind="attrs" v-on="on" @click="exportData" class="ml-1" icon><v-icon>mdi-table-arrow-down</v-icon></v-btn>
    </template>
    Esporta File JSON
  </v-tooltip>
</v-sheet>
</template>

<script>
export default {
  name: "MjJsonImportExport",
  props:{
    'data':{},
    'downloadFileName':{default:function(){return 'export'+new Date().getTime();}},
    },
  data() {
    return { uploaded_file:null}
  },
  methods:{
    exportData()
    {
      let json_data = "data:text/json;charset=utf-8,";
      json_data += encodeURIComponent(JSON.stringify(this.data,null,2)); //null e 2 sono per il pretty print
      const link = document.createElement("a");
      link.setAttribute("href", json_data);
      link.setAttribute("download", this.downloadFileName + ".json");
      link.click();
      link.remove();
    }
  },
  watch:{
    uploaded_file:function(){
      let reader = new FileReader();
      reader.onload = (e) => {
         this.$emit("loaded-data",e.target.result);
        }
      reader.readAsText(this.uploaded_file);
    }
  }
}
</script>

