/*
Premessa: eis stà per EventIntervalSetup
per il v-calendar è un event, ma trasporta anche altre info che servono per il particolare progetto.

Carico Gli orari di apertura tramite ajax (tali orari servono a determinare gli slot non utilizzabili)
Lo slot viene graficamente segnato inutilizzabile mediante il vue-slot interval e usando la funzione isClosedSlot.

Determino il più piccolo e il più grande orario della settimana sfruttando la struttura (orari apertura caricati in precedenza).
al fine di non mostrare gli intervalli orari non utilizzati in nessuna giornata.
----------------
Carico i ProductItems con la seguente struttura (necessaria per capire i possibili prodotti da inserire a calendario v-item-group)
Il formato è il seguente
* this.product_items = {  'NKJU10': {
*                             product_info: {cod: 'NK', title: 'Kart', icon: 'mdi-go-kart', color: '#32a852', ..},
*                             product_category: {cod: 'JU', title: 'Junior'},
*                             product_session_type: {cod: '10', title: '10 Min'},
*                             accepted_payment_type_cods: [PP,CC, CA_ON]
*                             price: 100,
*                             discount:30,
*                             discount_prepaid:20,
*                             max_bookable: 10
*                       },
*..
* con i computed vengono fatte delle elaborazioni per ottenere derivati di questa struttura.
-----------------

Sul beforeMount vengono anche caricati tutte le tipologie di pagamento disponibili (che servono nel popup modal del singolo eis)

----------
In ogni slot viene associato uno o più eventi che conterrano i seguenti dati
* [ { "name": "NKJU10",
*      "cod": "NKJU10",
*      "price": 100,
*      "discount": 30,
*      "discount_prepaid": 20, "count_booked":0, "max_bookable":22,
*      "accepted_payment_type_cods": [],
*      "category": "NK",
*      "color": "#32a852",
*      "start": 1610265600000,
*      "end": 1610267400000,
*      "title":"prova di titolo"
*      "description": 'prova di nota ad esempio per descrivere l'evento o la particolare configurazione in agenda'
*      ..
*   }
*]

Gli eis vengono caricati
- ogni qualvolta c'è un evento onChange sul v-calendar,
- su pressione di meta+L o v-btn load.
- Infine viene fatto un caricamento su alcuni casi di eliminazione di eis


Gli eis vengono salvati
- ogni qualvolta si cambia il tipo di calendario (da agenda a weektemplate ad esempio)
- ogni qualvolta si cambia la visualizzazione (day, week)
- sul calendar shift (quando si clicca quindi su prev e next)
- su pressione di meta+S o v-btn save.
- Infine viene fatto un salvataggio su alcuni casi di eliminazione di eis (prima del relativo caricamento per non perdere le modifiche)


note grafiche

- interval-count è a 48 perchè gli slot sono da 30 minuti (24h ci sono 48 intervalli)

*/

<template>
  <v-container fluid>
    <!--fake span for shortkey-->
    <span v-shortkey="['meta', 's']" @shortkey="saveEventsIntervalSetup(selected_calendar_type)"></span>
    <span v-shortkey="['meta', 'l']" @shortkey="loadEventsIntervalSetup"></span>

    <!--PARCO PRODUCT_ITEMS-->
    <v-progress-linear v-show="product_loader > 0" indeterminate></v-progress-linear>
    <v-card outlined class="mb-3">
      <v-card-title class="overline text--darken-2 mx-0 py-0 grey--text">Parco Prodotti</v-card-title>
      <v-card-text>
        <v-item-group
            v-model="selected_item_cods"
            active-class="primary accent-4"
            multiple
        >
          <v-row dense>
            <v-col v-for="product_info in products_info" :key="product_info.cod" cols="4">
              <v-btn icon>
                <v-icon :color="product_info.color" @click="selectProductItems(product_info.cod)" small>{{
                    product_info.icon
                  }}
                </v-icon>
              </v-btn>
              <v-item v-slot="{active,toggle}" :value="product_item.cod"
                      v-for="product_item in (product_items_with(product_info.cod))"
                      :key="product_item.cod">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">

                    <v-chip v-bind="attrs" v-on="on" @click="toggle" small :outlined="!active"
                            class="px-2 mt-1 mr-2 text--primary"
                            :color="(active)? 'primary' : product_info.color">
                      {{ product_item.cod }}
                    </v-chip>
                  </template>
                  {{ getProductItemDescription(product_item[product_item.cod]) }}
                </v-tooltip>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card-text>
    </v-card>
    <!--VCALENDAR TOOLBAR-->
    <v-row dense elevation="0" color="transparent" class="mx-0 px-0">

      <v-btn-toggle
          class="mr-2"
          active-class="primary"
          v-model="selected_calendar_type"
          style="background-color: transparent"
          @change="changeCalendarType"
          mandatory
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" outlined small icon value="week_template">
              <v-icon small>mdi-calendar-range-outline</v-icon>
            </v-btn>
          </template>
          Settimana Tipo
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">

            <v-btn v-bind="attrs" v-on="on" outlined small icon value="agenda">
              <v-icon small>mdi-calendar-month-outline</v-icon>

            </v-btn>
          </template>
          Agenda
        </v-tooltip>
<!--        <v-tooltip top>-->
<!--          <template v-slot:activator="{ on, attrs }">-->

<!--            <v-btn v-bind="attrs" v-on="on" outlined small icon value="cal_prenotazioni">-->
<!--              <v-icon small>mdi-calendar-account-outline</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          Prenotazioni-->
<!--        </v-tooltip>-->

      </v-btn-toggle>
      <v-btn-toggle
          active-class="primary"
          multiple
          style="background-color: transparent"
      >
        <v-btn icon small @click="event_detail_options.show_price = !event_detail_options.show_price">
          <v-icon small>mdi-currency-eur</v-icon>
        </v-btn>
        <v-btn icon small @click="event_detail_options.show_discount = !event_detail_options.show_discount">
          <v-icon small>mdi-percent</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle class="ml-2" style="background-color: transparent">
        <v-btn
            icon
            small
            @click="loadEventsIntervalSetup"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
            icon
            small
            @click="saveEventsIntervalSetup(selected_calendar_type)"
        >
          <v-icon small>mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn small icon color="info" @click="dialog_instruction_visible = true">
          <v-icon small>mdi-information-outline</v-icon>
        </v-btn>
      </v-btn-toggle>
      <div class="overline ml-5">{{ this.selected_calendar_type === 'week_template' ? 'Settimana Tipo' : 'Agenda'  }}</div>
      <v-spacer></v-spacer>
      <v-btn
          v-show="selected_calendar_type!=='week_template'"
          icon
          small
          class="ml-3"
          @click="vCalendarShift('l')"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
          v-show="selected_calendar_type!=='week_template'"
          icon
          small
          class="ml-2 mr-0"
          @click="vCalendarShift('r')"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <!--VCALENDAR-->
    <v-row class="fill-height ">
      <v-col>
        <v-sheet height="1000">
          <v-progress-linear v-show="calendar_loader > 0" indeterminate></v-progress-linear>
          <v-calendar
              v-model="calendar_model"
              ref="calendar"
              color="primary"
              :weekdays="[1,2,3,4,5,6,0]"
              :type="calendar_view_mode"
              :now="moment().format('yyyy-MM-DD HH:mm:ss')"
              :start="vcalendar_start"
              :event-timed="()=>true"
              event-overlap-mode="column"
              :interval-minutes="interval_minutes"
              :interval-count="interval_count"
              :first-interval="first_interval"
              interval-height="100"
              :day-format=" (d) => moment(d.date).locale('it').format('DD/MM')"
              locale="it"
              :short-intervals="false"
              :events="eventsIntervalSetup"
              :event-color="getEventColor"
              @click:day="changeCalendarViewMode"
              @click:date="changeCalendarViewMode"

              @change="changeVCalendar"
              @contextmenu:event="eventRightClick"
              @click:event="showEventPopup"
              @mousedown:event="startDrag"
              @mousedown:time="mouseDown"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"

              @mouseleave.native="cancelDrag"
          >
            <!-- v-slot per intestazione giorno (nome giorno) se il calendario attivo è week_template  lo slot per non mostrare nulla altrimenti usa l'implementazione di default-->
            <template v-if="this.selected_calendar_type === 'week_template' " v-slot:day-label-header="{}">
              <div></div>
            </template>
            <!-- v-slot per intestazione giorno (sotto nome giorno)-->
            <template v-if="this.selected_calendar_type !== 'week_template' " v-slot:day-header="{date}">
              <v-row align="center" justify="center">
                <v-btn icon class=" text-center py-2" @click.stop="showBookings(date)">
                  <v-icon small>mdi-calendar-edit</v-icon>
                </v-btn>
              </v-row>
            </template>
            <!-- v-slot per la cella del calendario (che chiamiamo slot) -->
            <template v-slot:interval="{weekday,hour,minute}">
              <div v-bind:class="[isClosedSlot(weekday,hour,minute) ? 'closed_interval' : '', 'interval']">
                <v-icon v-if="isClosedSlot(weekday,hour,minute)">mdi-close</v-icon>
              </div>
            </template>
            <template v-slot:event="{ event, timed /*,eventSummary*/ }">
              <div class="v-event-draggable" :style="`${getEventBackgroundStyle(event)}; height: 100%;`">
                <div class="v-event-draggable">
                  <div class="font-weight-bold">{{ event.product_item_key }}</div>
                  <div v-if="event_detail_options.show_price">{{
                      new Intl.NumberFormat('it-IT', {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 0
                      }).format(event.price)
                    }}
                  </div>
                  <div v-if="event_detail_options.show_discount">{{
                      new Intl.NumberFormat('it-IT', {
                        style: 'percent',
                        currency: 'EUR',
                        maximumFractionDigits: 0
                      }).format(event.discount / 100)
                    }}
                  </div>
                  <div v-if="event_detail_options.show_discount">{{
                      new Intl.NumberFormat('it-IT', {
                        style: 'percent',
                        currency: 'EUR',
                        maximumFractionDigits: 0
                      }).format(event.discount_prepaid / 100)
                    }} pre
                  </div>
                  <div class="body-1 pr-6" style="font-family: 'Arial Black' !important">
                    {{ (event.booking_aggregates) ? event.booking_aggregates.sum_n_partecipants : '0' }} / {{
                      event.max_bookable
                    }}
                  </div>
                </div>
              </div>
              <div
                  v-if="timed"
                  class="v-event-drag-bottom"
                  @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <!--INSTRUCTIONS-->
    <MjConfirmDialog v-model="dialog_instruction_visible" :confirm_button_visible="false" cancel_button_text="Ok"
                     dialog_title="Istruzioni" @cancel-from-dialog="dialog_instruction_visible = false">
      <template>
        <ul class="mx-6 px-3 small">
          <li>Con il tasto destro si cancellano gli elementi.</li>
          <li>Cliccando sul nome (LUN,MAR,..) del giorno si visualizza il dettaglio del singolo giorno</li>
          <li>I Rettangoli con le barre diagonali come sfondo rappresentano configurazioni della settimana tipo</li>
        </ul>
      </template>
    </MjConfirmDialog>
    <v-divider class="my-2"/>
    <!--IMPORT/EXPORT CALENDAR DATA-->
    <v-row class="mx-6 px-3">
      <span class="overline">Importa/Esporta Calendario</span>
      <MjJsonImportExport class='ml-2' :data="eventsIntervalSetup" downloadFileName="calendario_settimanale"
                          @loaded-data="function(data){eventsIntervalSetup = JSON.parse(data)}"></MjJsonImportExport>
    </v-row>
    <!--EventIntervalSetup Popup-->
    <v-dialog
        v-model="eventPopupVisible"
        :close-on-content-click="false"
        :activator="selectedEventForPopupNative"
        offset-x
        max-width="640px"
        elevation="11"

    >
      <v-card outlined shaped v-if="selectedEventForPopup">
        <v-card-title
            v-bind:style="{backgroundColor:product_items[selectedEventForPopup.product_item_key].product_info.color}">
          {{ this.selectedEventForPopup.product_item_key }}
          <v-spacer></v-spacer>
          <v-btn @click="eventPopupVisible = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle
            v-bind:style="{backgroundColor:product_items[selectedEventForPopup.product_item_key].product_info.color}"
            class="popup_subtitle">{{
            (this.product_items[this.selectedEventForPopup.product_item_key]['product_info']) ? this.product_items[this.selectedEventForPopup.product_item_key]['product_info']['title'] : ''
          }}
          {{
            (this.product_items[this.selectedEventForPopup.product_item_key]['product_category']) ? this.product_items[this.selectedEventForPopup.product_item_key]['product_category']['title'] : ''
          }}
          {{
            (this.product_items[this.selectedEventForPopup.product_item_key]['product_session_type']) ? this.product_items[this.selectedEventForPopup.product_item_key]['product_session_type']['title'] : ''
          }} /
          {{ moment(this.selectedEventForPopup.start).locale('it').format("ddd HH:mm") }}
        </v-card-subtitle>
        <v-card-text>

          <v-row>

            <v-col cols="3">

              <!--              <v-text-field @change="changeEventPopup" dense v-model="selectedEventForPopup.price" prefix="€" label="Prezzo"></v-text-field>-->
              <mj-text-field @change="changeEventPopup" max_fraction_digits="0" type="money" dense
                             v-model="selectedEventForPopup.price" prefix="€" label="Prezzo"></mj-text-field>
            </v-col>
            <v-col cols="3">
              <!--              <v-text-field @change="changeEventPopup" dense v-model="selectedEventForPopup.discount" prefix="%" label="Sconto"></v-text-field>-->
              <mj-text-field @change="changeEventPopup" max_fraction_digits="0" type="percent" dense
                             v-model="selectedEventForPopup.discount" prefix="%" label="Sconto"></mj-text-field>

            </v-col>
            <v-col cols="5">
              <!--              <v-text-field @change="changeEventPopup" dense v-model="selectedEventForPopup.discount_prepaid" prefix="%"-->
              <!--                            label="Sconto Prepagato"></v-text-field>-->
              <mj-text-field @change="changeEventPopup" max_fraction_digits="0" type="percent" dense
                             v-model="selectedEventForPopup.discount_prepaid" prefix="%"
                             label="Sconto Prepagato"></mj-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" v-if="selected_calendar_type !== 'week_template'">
              <v-text-field type="text" disabled @change="changeEventPopup" dense
                            :value="(selectedEventForPopup.booking_aggregates) ? selectedEventForPopup.booking_aggregates.sum_n_partecipants : 0"
                            label="Partecipanti prenotati"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field type="number" @change="changeEventPopup" dense v-model="selectedEventForPopup.max_bookable"
                            label="Max. Posti Disponibili"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">

              <v-btn-toggle multiple label="Modalità di Pagamento Accettate" active-class="primary"
                            v-model="selectedEventForPopup.accepted_payment_type_cods" dense
                            @change="changeEventPopup"
              >
                <v-tooltip top v-for="flag in payment_types" :key="flag.cod">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" :value="flag.cod" icon>
                      <v-icon>{{ flag.icon }}</v-icon>
                    </v-btn>
                  </template>
                  Modalità Pagamento Accettata: <br><b>{{ flag.description }}</b>
                </v-tooltip>
              </v-btn-toggle>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field class="mt-3" @change="changeEventPopup" dense v-model="selectedEventForPopup.title"
                            label="Titolo"
                            hint="Utilizzato per descrivere un evento, o annotare qualcosa su questo settaggio.."
                            persistent-hint></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea @change="changeEventPopup" dense v-model="selectedEventForPopup.description" rows="2"
                          label="Descrizione"
                          hint="Utilizzato per descrivere un evento, o annotare qualcosa su questo settaggio.."
                          persistent-hint></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <!--          <v-btn @click="saveEventIntervalSetup(selectedEventForPopup); eventPopupVisible = false;"  icon color="primary lighten-1" >-->
          <!--             <v-icon right >mdi-content-save-outline</v-icon>-->
          <!--          </v-btn>-->
          <v-btn color="secondary" class="ml-4" small v-if="selected_calendar_type !== 'week_template'"
                 @click="addBooking">

            <v-icon>mdi-plus</v-icon>
            Prenotazione
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="deleteEventIntervalSetup(selectedEventForPopup); eventPopupVisible = false;" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    SNACKBAR-->
    <v-snackbar v-model="snackbar_visible" color="warning" right timeout="6000">
      <v-icon>mdi-information-outline</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
    <!--  SAVED SNACKBAR-->
    <v-snackbar v-model="snackbar_saved_visible" app left timeout="2000" color="transparent">
      <v-btn x-small class="p-4">
        <v-icon small class="mr-1">mdi-information-outline</v-icon>
        Calendario Salvato
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<style scoped lang="scss">

.popup_title {
  background-color: #444;
  padding-bottom: 20px;
}

.popup_subtitle {
  background-color: #444;
  border-bottom: solid 2px #999;
  margin-bottom: 10px
}

.interval {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.closed_interval {
  background-color: #333;
  opacity: 0.1;
}

;
.v-event-draggable {
  padding-left: 6px;

}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;

}

.v-event-drag-bottom {

  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }

}


</style>

<script>
import moment from "moment";
import mj_axios from "@/mj_axios.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js"

import mj_color_utils from "@/misc/mj_color_utils.js";
import MjConfirmDialog from "@/components/MjConfirmDialog.vue";
import MjJsonImportExport from "@/components/data/MjJsonImportExport";
import MjTextField from "@/components/form/MjTextField";

export default {
  name: "CustomWeeklyConfigView",
  components: {MjTextField, MjJsonImportExport, MjConfirmDialog},
  mixins: [ValidationRulesMixin],
  beforeMount() {



    this.loadPaymentTypes();
    this.loadWeeklyOpeningHours(); //all'interno dopo il caricamento viene chiamato this.computeFirstInterval();
    this.loadProductItems();
    // this.loadEventsIntervalSetup(); //non necessario perchè viene fatto un @change già all'inizio
  },
  props: {},
  computed: {
    products_info: function () {
      let result = [];
      for (const p of Object.values(this.product_items)) {
        if (!result.some(item => item.cod === p.product_info.cod)) {
          result.push(p.product_info);
        }
      }
      return result;
    },
    product_items_with: function () {
      //sintassi per usare il parametro product_cod nella computed (non possibile in function(product_cod))
      return product_cod => {
        //ottengo le chiavi che rispettando il criterio di avere un product_info.cod uguale al parametro product_cod
        const keys = Object.keys(this.product_items).filter(key => this.product_items[key].product_info.cod === product_cod);
        return keys.map(key => {
          const product_item = {};
          product_item["cod"] = key;
          product_item[key] = this.product_items[key];
          return product_item;
        });
      }
    }
  },
  data: () => ({
    vcalendar_start: '1990-01-01', //collegato alla prop del v-calendar (giorno 1 è lunedì, punto di partenza della settimana tipo per far combaciare una settimana che inizia per domenica)

    calendar_from: '1990-01-01 00:00:00', //utilizzato per capire il calendario quale periodo stà considerando (tieni conto che se imposti now come v_calendar_start il from non sarà il v_calendar_start ma la prima domenica antecedente
    calendar_to: '1990-01-07 23:59:59',

    event_detail_options: {'show_price': false, 'show_discount': false},
    selected_item_cods: [],
    product_items: [],
    product_loader: 0,
    selected_calendar_type: 'week_template',
    previous_selected_calendar_type: 'week_template', //memorizza la scelta precedente (usato per il salvataggio)

    calendar_view_mode: 'week',
    calendar_model: '',
    interval_minutes: 30,
    interval_count: 10,
    first_interval: 10,
    eventsIntervalSetup: [],
    calendar_loader: 0,
    event_to_extend: null,

    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    latestMouseDown: 0, //usato per gestire showEventPopup click vs mouseup (altrimenti dopo il drag si apre il popup)


    snackbar_visible: false,
    snackbar_saved_visible: false,
    snackbar_text: '',
    eventPopupVisible: false,
    selectedEventForPopup: false,
    selectedEventForPopupNative: null,
    dialog_instruction_visible: false,

    payment_types: [
      //caricati tramite ajax
      // {text: 'Prepagato', value: 'PP', icon: 'mdi-piggy-bank-outline'},
      // {text: 'Carta Online', value: 'CC', icon: 'mdi-credit-card-outline'},
      // {text: 'Cassa', value: 'CA', icon: 'mdi-cash'}
    ],

    weekly_opening_hours: [],
  }),
  methods: {

    //per uso nei tag del template
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },

    //-------------------------TIPOLOGIE PAGAMENTO-----------------
    loadPaymentTypes() {
      this.product_loader++;
      const axios_promise = mj_axios.get('/api/payment_type');
      axios_promise.then(response => {
        this.payment_types = response.data.data;
      }).catch(() => {

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento delle tipologie di pagamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.product_loader--;
      });
      return axios_promise;

    },

    //------------------------ORARI APERTURA-------------------------------
    /*
     * Carica gli orari in cui è aperto in una settimana standard
     */
    loadWeeklyOpeningHours() {

      this.calendar_loader++;
      const axios_promise = mj_axios.get('/api/admin/weekly_opening_hours');
      axios_promise.then(response => {
        this.weekly_opening_hours = response.data;
        this.computeFirstInterval();
      }).catch(() => {

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento degli orari settimanali, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.calendar_loader--;
      });
      return axios_promise;


      //TEST DATA
      // this.weekly_opening_hours = {
      //   0: [{start_hour: '0900', end_hour: '0930'},{start_hour: '1100', end_hour: '1130'},],
      //   1: [{start_hour: '1000', end_hour: '1030'},{start_hour: '1330', end_hour: '1400'},]
      // }
    },

    /**
     * Sulla base degli orari di apertura calcolo il più piccolo e il più grande slot in modo da togliere gli intervalli inutili
     * (es. se è sempre chiuso prima delle 9.00 inutile mostrare le fasce precedenti.
     */
    computeFirstInterval() {

      if (this.weekly_opening_hours == null || this.weekly_opening_hours.length == 0) {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Non è disponibile nel database alcun orario di apertura, La griglia del calendario mostrerà tutti gli intervalli orari della giornata",
          visible: true,
        });

        this.first_interval = 0;
        this.interval_count = Math.floor((24 * 60) / this.interval_minutes);
      } else {
        const orari_apertura_ordinari_min_max_in_a_week = {min: 2400, max: 0};
        for (let day_slots of Object.values(this.weekly_opening_hours)) {
          for (let day_slot of day_slots) {

            if (parseInt(day_slot.start_hour) < parseInt(orari_apertura_ordinari_min_max_in_a_week.min)) {
              orari_apertura_ordinari_min_max_in_a_week.min = day_slot.start_hour;
            }
            if (parseInt(day_slot.end_hour) > parseInt(orari_apertura_ordinari_min_max_in_a_week.max)) {
              orari_apertura_ordinari_min_max_in_a_week.max = day_slot.end_hour;
            }
          }
        }
        const moment_min = (moment(orari_apertura_ordinari_min_max_in_a_week.min, "HH:mm"));
        const moment_max = (moment(orari_apertura_ordinari_min_max_in_a_week.max, "HH:mm"));
        const moment_minutes_from_start_day_to_min = moment_min.clone().startOf('day');
        const minutes_from_start_day_to_min = (moment_min.diff(moment_minutes_from_start_day_to_min, 'minutes'));
        const minutes_from_min_to_max = (moment_max.diff(moment_min, 'minutes'));

        this.first_interval = Math.floor(minutes_from_start_day_to_min / this.interval_minutes);
        this.interval_count = Math.floor(minutes_from_min_to_max / this.interval_minutes);
      }
    },

    /**
     * Determino dato uno slot se è chiuso o meno.
     * E' chiuso quando non c'è uno orario di apertura per tale orario
     * @param weekday
     * @param hour
     * @param minute
     * @returns {boolean}
     */
    isClosedSlot(weekday, hour, minute) {
      let ret = true;
      const hour_minute = hour.toString().padStart(2, '0') + minute.toString().padEnd(2, '0');

      if (this.weekly_opening_hours[weekday]) {
        const day_slots = this.weekly_opening_hours[weekday];

        for (let day_slot of day_slots) {
          const is_open = ((hour_minute < day_slot.end_hour && hour_minute >= day_slot.start_hour));
          //
          if (is_open) {
            ret = false;
            break; //break loop
          }
        }
      }
      return ret;
    },

    //----------------------------------PRODOTTI-----------------------------------
    async loadProductItems() {
      this.product_loader++;
      const axios_promise = mj_axios.get('/api/product_item');
      axios_promise.then(response => {
        this.product_items = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento del parco prodotti, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.product_loader--;
      });
      return axios_promise;
    },
    selectProductItems(product_cod) {

      //ottengo i codici dei product item che voglio selezionare
      const product_cods_to_be_selected = this.product_items_with(product_cod).map(p => p.cod);
      //verifico se sono già tutti selezionati
      if (product_cods_to_be_selected.every(p => this.selected_item_cods.includes(p)))
          //se lo sono, tolgo la selezione
      {
        this.selected_item_cods = this.selected_item_cods.filter(p => !product_cods_to_be_selected.includes(p));
      }
      //altrimenti l'aggiunto
      else {
        this.selected_item_cods = this.selected_item_cods.concat(product_cods_to_be_selected);
        //elimino i duplicati
        this.selected_item_cods = [...new Set(this.selected_item_cods)];
      }
    },
    getProductItemDescription(product_item) {
      const product_info_title = (product_item.product_info && product_item.product_info.title) ? product_item.product_info.title : '';
      const product_category_title = (product_item.product_category && product_item.product_category.title) ? product_item.product_category.title : '';
      const product_session_type_title = (product_item.product_session_type && product_item.product_session_type.title) ? product_item.product_session_type.title : '';
      return `${product_info_title} ${product_category_title} ${product_session_type_title}`;
    },

    //--------------------------------EVENTI V-CALENDAR----------------------

    //Cambia il tipo di calendario (week_template, agenda, etc.)
    async changeCalendarType() {

      this.$route.query.calendar_type = this.selected_calendar_type;
      //save deve stare prima del cambio date
      //visto che già il selected_calendar_type è cambiato ottengo il valore precedente
      await this.saveEventsIntervalSetup(this.previous_selected_calendar_type);

      if (this.selected_calendar_type === 'week_template') {
        this.calendar_model = this.vcalendar_start = '1990-01-01'
      } else {
        this.calendar_model = this.vcalendar_start = moment().format('yyyy-MM-DD');
      }
      this.previous_selected_calendar_type = this.selected_calendar_type;
    },

    //Cambia la tipologia di visualizzazione day, week etc.
    async changeCalendarViewMode(evt) {

      await this.saveEventsIntervalSetup(this.selected_calendar_type);

      if (this.calendar_view_mode === 'week') {
        this.calendar_view_mode = 'day';
        this.calendar_model = this.vcalendar_start = evt.date;

      } else {
        this.calendar_view_mode = 'week';
      }

      //implementazione con rotazione anche su category day mode.
      // if (this.calendar_view_mode === 'week') {
      //   this.calendar_view_mode = 'category';
      // } else if (this.calendar_view_mode === 'category') {
      //   this.calendar_view_mode = 'day';
      // } else if (this.calendar_view_mode === 'day') {
      //   this.calendar_view_mode = 'week';
      // }

    },
    //serve a muovere le settimane o cmq il v-calendar avanti e indietro nel tempo
    vCalendarShift(direction) {
      if (direction === 'l') {

        const now = moment();
        //quando faccio lo shift il calendar_from e to sono ancora quelli prima dell'avvenuto shift (infatti saranno aggiornati al calendar change)
        //quindi se now si trova dentro from-to vuol dire che stò facendo un calendar.prev dalla settimana corrente verso sinistra (indietro) => VIETATO => SNACKBAR!
        if (now.isBetween(moment(this.calendar_from), moment(this.calendar_to))) {
          this.snackbar_text = "Non puoi visionare il calendario per le settimane precedenti";
          this.snackbar_visible = true;
        } else {

          this.$refs.calendar.prev();
        }

      } else if (direction === 'r') {
        this.$refs.calendar.next();
      }
      this.saveEventsIntervalSetup(this.selected_calendar_type)
    },
    //evento di onChange del v-calendar
    changeVCalendar(changeEvt) {

      this.calendar_from = changeEvt.start.date + " 00:00:00";
      //in mysql non esiste 24.00 quindi devo calcolare il giorno successivo
      this.calendar_to = changeEvt.end.date + " 23:59:59";

      this.loadEventsIntervalSetup();
    },

//--------------------------------EVENT INTERVAL SETUP (EIS)----------------------
    async loadEventsIntervalSetup() {

      this.calendar_loader++;
      this.selectedEventForPopup = false; //resetto il selected, necessario altrimenti su refresh del calendario non aggiorna i dati
      this.selectedEventForPopupNative = null;

      const params = {
        "calendar_type": this.selected_calendar_type,
        "calendar_from": this.calendar_from,
        "calendar_to": this.calendar_to
      };

      // console.log('load ' + this.selected_calendar_type + ' ' + this.calendar_from + ' -> ' + this.calendar_to);

      const axios_promise = mj_axios.get('/api/admin/event_interval_setup', {params: params});
      axios_promise.then(response => {
        this.eventsIntervalSetup = response.data.data;
      }).catch(() => {
        this.eventsIntervalSetup = [];
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento delle configurazioni settimana tipo / agenda, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.calendar_loader--;
      });
      return axios_promise;

      //   if (this.selected_calendar_type === 'week_template') {
      //     this.eventsIntervalSetup = [{
      //       "name": "NKJU10",
      //       "product_item_key": "NKJU10",
      //       "calendar_type": "week_template",
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": ['PP', 'CC'],
      //       "category": "NK",
      //       "description": "prova di descrizione",
      //       "color": "#32a852",
      //       "start": '1990-01-01 13:30:00',
      //       "end": '1990-01-01 14:30:00',
      //       // "start": 288000000,
      //       // "end": 289800000,
      //       // "timed": true
      //     }, {
      //       "name": "NKJUMG",
      //       "product_item_key": "NKJUMG",
      //       "calendar_type": "week_template",
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "NK",
      //       "description": "prova di descrizione",
      //       "color": "#32a852",
      //       "start": 288000000,
      //       "end": 289800000,
      //       "timed": true
      //     }, {
      //       "name": "NM--10",
      //       "product_item_key": "NM--10",
      //       "calendar_type": "week_template",
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "NM",
      //       "description": "prova di descrizione",
      //       "color": "#3273a8",
      //       "start": 288000000,
      //       "end": 289800000,
      //       "timed": true
      //     }, {
      //       "name": "NM--MG",
      //       "product_item_key": "NM--MG",
      //       "calendar_type": "week_template",
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "NM",
      //       "description": "prova di descrizione",
      //       "color": "#3273a8",
      //       "start": 288000000,
      //       "end": 289800000,
      //       "timed": true
      //     }, {
      //       "name": "NM--GP",
      //       "product_item_key": "NM--GP",
      //       "calendar_type": "week_template",
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "NM",
      //       "description": "prova di descrizione",
      //       "color": "#3273a8",
      //       "start": 288000000,
      //       "end": 289800000,
      //       "timed": true
      //     }, {
      //       "name": "PRKA--",
      //       "product_item_key": "PRKA--",
      //       "calendar_type": "week_template",
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "PR",
      //       "description": "prova di descrizione",
      //       "color": "#a86332",
      //       "start": 378000000,
      //       "end": 379800000,
      //       "timed": true
      //     }, {
      //       "name": "PRMO--",
      //       "product_item_key": "PRMO--",
      //       "calendar_type": "week_template",
      //
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "PR",
      //       "description": "prova di descrizione",
      //       "color": "#a86332",
      //       "start": 378000000,
      //       "end": 379800000,
      //       "timed": true
      //     }, {
      //       "name": "NM--10",
      //       "product_item_key": "NM--10",
      //       "calendar_type": "week_template",
      //
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "NM",
      //       "description": "prova di descrizione",
      //       "color": "#3273a8",
      //       "start": 547200000,
      //       "end": 549000000,
      //       "timed": true
      //     }, {
      //       "name": "NM--MG",
      //       "product_item_key": "NM--MG",
      //       "calendar_type": "week_template",
      //
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "NM",
      //       "description": "prova di descrizione",
      //       "color": "#3273a8",
      //       "start": 547200000,
      //       "end": 549000000,
      //       "timed": true
      //     }, {
      //       "name": "NM--GP",
      //       "product_item_key": "NM--GP",
      //       "calendar_type": "week_template",
      //
      //       "price": 100,
      //       "discount": 30,
      //       "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //       "accepted_payment_type_cods": [],
      //       "category": "NM",
      //       "description": "prova di descrizione",
      //       "color": "#3273a8",
      //       "start": 547200000,
      //       "end": 549000000,
      //       "timed": true
      //     }];
      //   }
      //   if (this.selected_calendar_type === 'agenda') {
      //     //attenzione nei dati di test non vengono riallocati gli elementi della settimana tipo, ma ne viene creato solo uno di esempio
      //     this.eventsIntervalSetup = [
      //       {
      //         "name": "NKJU10",
      //         "product_item_key": "NKJU10",
      //         "calendar_type": "week_template",
      //
      //         "price": 100,
      //         "discount": 30,
      //         "discount_prepaid": 20, "count_booked": 0, "max_bookable": 22,
      //         "accepted_payment_type_cods": [],
      //         "category": "NK",
      //         "description": "prova di descrizione",
      //         "color": "#32a852",
      //         "start": 1611043200000,
      //         "end": 1611045000000,
      //         "timed": true,
      //
      //       }
      //     ]
      //   }
    },
    async saveEventsIntervalSetup(calendar_type) {

      this.calendar_loader++;

      const eventsIntervalSetupPurged = [];
      this.eventsIntervalSetup.forEach(function (eis) {
        if (eis.calendar_type === calendar_type) //devo inviare solo gli eis dello stesso tipo dell'attuale calendario, per evitare che se sono in agenda mando anche i week_template rilocati
        {
          const new_eis = {...eis} //clona
          delete new_eis.product_item_key;
          delete new_eis.name;
          delete new_eis.category;
          delete new_eis.color;
          delete new_eis.timed;
          delete new_eis.booking_aggregates;
          eventsIntervalSetupPurged.push(new_eis);
        }

      });

      const params = {
        "calendar_type": calendar_type,
        "calendar_from": this.calendar_from,
        "calendar_to": this.calendar_to,
        "event_interval_setups": eventsIntervalSetupPurged
      };

      // console.log('saving ' + calendar_type + ' ' + this.calendar_from + ' -> ' + this.calendar_to);

      const axios_promise = mj_axios.post('/api/admin/event_interval_setup', params);
      axios_promise.then(() => {

        this.snackbar_saved_visible = true;

      }).catch((e) => {

        // const status = (e.response) ? e.response.status : null;
        let message = (e.response) && (e.response.data) ? e.response.data.message : '';

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di salvataggio delle configurazioni settimana tipo / agenda , è consigliato, risolvere il problema. ${message}`,
          visible: true,
        });
      }).then(() => {
        this.calendar_loader--;
      });
      return axios_promise;
    },
    /*AddEvent*/
    addEventIntervalSetup(eventStart) {
      // const mouse = this.toTime(tms)
      if (this.selected_item_cods && this.selected_item_cods.length > 0) {
        const clickedDate = moment(eventStart);
        const eventIntervalSetupMoment = clickedDate.subtract(moment(eventStart).minutes() % this.interval_minutes, 'minutes');
        const eventIntervalSetupStart = eventIntervalSetupMoment.format("YYYY-MM-DD HH:mm");
        const eventIntervalSetupEndMoment = eventIntervalSetupMoment.add(this.interval_minutes, 'minutes');
        if (eventIntervalSetupEndMoment.format("HH:mm") === '00:00') {
          eventIntervalSetupEndMoment.add(-1, 'second');
        }
        // const eventIntervalSetupEnd = eventIntervalSetupStart + (this.interval_minutes * 60 * 1000);
        const eventIntervalSetupEnd = eventIntervalSetupEndMoment.format("YYYY-MM-DD HH:mm");
        //

        this.selected_item_cods.forEach((selected_item_cod) => {

          //estraggo il product_item originale in modo da allegarlo all'evento e averne sempre le informazioni dietro (colore, icona etc.).
          const original_item = this.product_items[selected_item_cod];

          // console.log(original_item.product_info.cod);


          const eventIntervalSetup = {
            name: `${selected_item_cod}`,
            product_item_key: selected_item_cod,
            product_items_id: original_item.id, //serve per il salvataggio
            calendar_type: this.selected_calendar_type,
            price: original_item.price,
            discount: original_item.discount,
            state: 1,
            discount_prepaid: original_item.discount_prepaid,
            max_bookable: original_item.max_bookable,
            accepted_payment_type_cods: original_item.accepted_payment_type_cods,
            // category: original_item.product_info.cod,
            color: original_item.product_info.color,
            start: eventIntervalSetupStart,
            end: eventIntervalSetupEnd,
            timed: true,
          }


          if (this.existsEventIntervalSetup(eventIntervalSetup)) {
            this.snackbar_visible = true;
            this.snackbar_text = "Non puoi inserire due configurazioni per lo stesso prodotto e nella stessa fascia oraria!";
          } else {
            this.eventsIntervalSetup.push(eventIntervalSetup);
          }
        })
      }
    },

    // Abilita se vuoi implementare un salvataggio esplicito sul popup dell'eis
    // saveEventIntervalSetup(event) {
    //   console.log('INSIDE');
    //   const index = this.eventsIntervalSetup.indexOf(event);
    //   if (index > -1) {
    //     //se siamo in modalità agenda e modifico un EventIntervalSetupW lo trasformo in EventIntervalSetupA
    //     if (this.selected_calendar_type !== 'week_template' && event.calendar_type === 'week_template') {
    //       event.calendar_type = 'agenda';
    //     }
    //     this.eventsIntervalSetup[index] = event;
    //   }
    // },
    async deleteEventIntervalSetup(event) {
      const index = this.eventsIntervalSetup.indexOf(event);
      if (index > -1) {
        //se siamo in modalità agenda e stiamo cercando di cancellare un EISW allora lo trasformiamo in EISA con stato  = 0 (disattivato)
        if (this.selected_calendar_type === 'agenda' && event.calendar_type === 'week_template') {
          event.state = 0;
          event.calendar_type = 'agenda'
        }

        //in caso contrario lo eliminamo del tutto
        else {
          this.eventsIntervalSetup.splice(index, 1);

          //ma se ho cancellato un agenda con stato = 0 (vale a dire un eisw disabilitato) devo salvare e ricaricare in modo che
          //visualizzo nuovamente l'eisw originale che avevo cancellato
          if (this.selected_calendar_type === 'agenda' && event.state === 0) {
            await this.saveEventsIntervalSetup(this.selected_calendar_type);
            this.loadEventsIntervalSetup();

          }

        }
      }
    },

    //utilizzato da v-calendar per determinare il colore dell'eis
    getEventColor(event) {

      const product_item = this.product_items[event.product_item_key];
      if (product_item) {
        return (event.state === 0) ? '#555555' : product_item.product_info.color;
      } else return '#999999'; //colore di default in assenza di product item
    }
    ,
    //utilizzato per determinare negli eis (ovvero eventi nel v-calendar) il colore di sfondo
    getEventBackgroundStyle(event) {

      //ritorno lo sfondo diagonale, se sono EISW oppure EISA disabilitati (che non sono altro ex EISW)
      if (event.calendar_type === 'week_template' || event.state === 0) {
        const adjusted_color = mj_color_utils.adjust(this.getEventColor(event), 10);
        return `background: repeating-linear-gradient(-55deg,${this.getEventColor(event)},${this.getEventColor(event)} 10px,${adjusted_color}  10px,${adjusted_color} 20px)`;
      } else {
        return `background-color:${this.getEventColor(event)}`;
      }
    }
    ,
    //ritorna l'identificativo di un eventIntervalSetup
    getEventIntervalSetupCod(eventIntervalSetup) {
      const start = moment(eventIntervalSetup.start).format('YYYY-MM-DD HH:mm');
      const end = moment(eventIntervalSetup.start).format('YYYY-MM-DD HH:mm');
      // console.log(`${start}${end}${eventIntervalSetup.product_item_key}`);
      return `${start}${end}${eventIntervalSetup.product_item_key}`;
    },

    //verifica se l'intervalsetup esiste già nel v-calendar
    existsEventIntervalSetup(eventIntervalSetup) {
      let ret = false;
      if (this.eventsIntervalSetup) {
        const cod = this.getEventIntervalSetupCod(eventIntervalSetup);
        // console.log('existsEventIntervalSetup ' + cod);
        ret = this.eventsIntervalSetup.some(e => this.getEventIntervalSetupCod(e) === cod)
      }
      return ret;

    }
    ,
//-------------------------PRENOTAZIONI BOOKINGS-------------------------

    //mostra le prenotazioni di una determinata giornata.
    showBookings(date) {

      const params = {
        "date": date,
      }
      const get_params = new URLSearchParams(params).toString();
      let routeData = this.$router.resolve({name: "BookingHistory"});
      window.open(`${routeData.href}?${get_params}`, date, "location=0,width=780,height=800");
    },
    //consente dal popup di inserire una prenotazione relativa all'eis selezionato e correntemente visualizzato sul popup
    //vengono inviati tramite query string i valor dell'eis in modo da precompilare la prenotazione.
    addBooking() {

      const product_item_description = (this.product_items[this.selectedEventForPopup.product_item_key]['product_info']) ? this.product_items[this.selectedEventForPopup.product_item_key]['product_info']['title'] : '' +
      (this.product_items[this.selectedEventForPopup.product_item_key]['product_category']) ? this.product_items[this.selectedEventForPopup.product_item_key]['product_category']['title'] : '' +
      (this.product_items[this.selectedEventForPopup.product_item_key]['product_session_type']) ? this.product_items[this.selectedEventForPopup.product_item_key]['product_session_type']['title'] : '';


      const params = {
        "eis_relocated_key": this.selectedEventForPopup.key,
        "eis_original_key": this.selectedEventForPopup.original_key,
        "start": this.selectedEventForPopup.start,
        "end": this.selectedEventForPopup.end,
        "price": this.selectedEventForPopup.price,
        "discount": this.selectedEventForPopup.discount,
        "discount_prepaid": this.selectedEventForPopup.discount_prepaid,
        "product_item_description": product_item_description,
        "product_item_cod": this.selectedEventForPopup.product_item_key
      }

      const get_params = new URLSearchParams(params).toString();

      let routeData = this.$router.resolve({name: "NewSchedaBooking"});

      window.open(`${routeData.href}?${get_params}`, this.selectedEventForPopup.key, "location=0,status=no,menubar=no,titlebar=1,width=800,height=600");

    },
//----------------------DRAG & MOUSE EVENTS-----------------------*/
    eventRightClick(event) {
      this.deleteEventIntervalSetup(event.event);
    }
    ,

    showEventPopup({nativeEvent, event}) {
      const diffSinceLatestMouseDown = (new Date().getTime() - this.latestMouseDown);
      //mostro il popup se è un click veloce (no mouse up dopo un drag o extend)
      if (diffSinceLatestMouseDown < 500) {
        this.selectedEventForPopup = event;
        this.selectedEventForPopupNative = nativeEvent.target;
        this.eventPopupVisible = true;
        // nativeEvent.stopPropagation();
      }
    }
    ,

    //logiche di cambio tipologia nell'eis quando cambia un elemento del popup o modal dell'eis
    changeEventPopup() {
      if (this.selected_calendar_type === 'agenda' && this.selectedEventForPopup.calendar_type === 'week_template') {
        this.selectedEventForPopup.calendar_type = 'agenda';
      }
    }
    ,
    startDrag({event, timed}) {

      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    mouseDown(tms) {
      this.latestMouseDown = new Date().getTime();
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start
        this.dragTime = mouse - moment(start).valueOf();
      } else {
        this.createStart = this.roundTime(mouse)
        this.addEventIntervalSetup(this.createStart);
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = moment(this.dragEvent.start).valueOf();
        const end = moment(this.dragEvent.end).valueOf();
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = moment(newStart).format('YYYY-MM-DD HH:mm')
        this.dragEvent.end = moment(newEnd).format('YYYY-MM-DD HH:mm')

      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, moment(this.createStart).valueOf());
        const max = Math.max(mouseRounded, moment(this.createStart).valueOf());
        this.createEvent.start = moment(min).format("YYYY-MM-DD HH:mm");
        this.createEvent.end = moment(max).format("YYYY-MM-DD HH:mm");
      }
    },
    endDrag() {
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.eventsIntervalSetup.splice(i, 1)
          }
        }
      }
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = this.interval_minutes // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
  },
  watch:{


$route:{
   handler: async function() {
    //imposto agenda di default
    // console.log(this.$route.query.calendar_type);

    if (this.$route.query.calendar_type)
    {
      {
        this.selected_calendar_type = this.$route.query.calendar_type;
        //devo prima caricare, altrimenti il change genera un salvataggio con zero elementi
        await this.loadEventsIntervalSetup();
        this.changeCalendarType();
      }
    }
  },
  deep:true,
      immediate:true
}

  }
}
;
</script>
