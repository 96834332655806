/* 
SCOPO DEL COMPONENTE: MOSTRARE UNA V-COMBOBOX PER AZIONARE UN FILTRO.

INPUT: url per caricare gli item
nome della proprietà da mostrare
nome della proprietà da usare come valore

OUTPUT: array dei valori selezionati, modalità (and / or), glue (and or tra filtri - diversi mjcombobox)
*/

<template>
  <v-container>
    <v-combobox
      v-model="selected"
      :search-input.sync="search"
      :items="items"
      :label="label"
      :item-text="item_description_field"
      :item-value="item_value_field"
      hide-details
      multiple
      chips
      :loading="showLoader"
      @change="emitChange();search = ''"
      
    >
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          :close="true"
          small
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-avatar
            v-if="selected.length > 1 && selected[0] != data.item"
            class="accent white--text smaller"
            left
          >{{mode.toLowerCase()}}</v-avatar>
          {{
            (typeof item_description_field === "function") ? item_description_field(data.item) : data.item[item_description_field]
          }}
        </v-chip>
      </template>
      <template v-slot:append-outer>
        <v-btn
          fab
          small
          :class="(glue_data === 'AND') ? 'accent caption' : 'primary caption'"
          @click="(glue_data === 'OR') ? glue_data='AND': glue_data='OR';emitChange();"
          v-if="glue_data"
        >{{glue_data}}</v-btn>
      </template>
    </v-combobox>
    <div class="caption grey--text" v-show="hint.length > 0">
      {{hint}}
      <a v-show="selected.length > 1" @click.prevent="changeMode">Cambia Modo Ricerca</a>
    </div>
  </v-container>
</template>
<script>
import mj_axios from "@/mj_axios.js";
export default {
  name: "MjComboBoxFilter",
  props: {
    label: {},
    load_items_url: {},
    item_description_field: {},
    item_value_field: {},
    filter_name: {},
    relation_field_name: {},
    glue: { type: String },
    silent_reset: {}, //usata nel watch per capire se il parent chiede un reset
  },
  computed: {
    hint: function () {
      let hint = "";
      if (this.selected && this.selected.length > 0) {
            hint =
            "Ricerca valida se trovo  " +
            this.selected
              .map((item) => (typeof this.item_description_field === "function") ? this.item_description_field(item) : item[this.item_description_field])
              .join(this.mode === "AND" ? " e " : " oppure ") +
            ".";
      }

      return hint;
    },
  },
  data: function () {
    return {
      selected: [],
      glue_data: this.glue ? this.glue.toUpperCase() : this.glue,
      items: [],
      mode: "OR",
      showLoader: true,
      search: ''
    };
  },
  created() {
    this.loadItems();
  },
  methods: {
    getDescription: function(item){return (typeof item_description_field === "function") ? this.item_description_field(item) : item[this.item_description_field];},
    changeMode: function () {
      if (this.mode == "AND") this.mode = "OR";
      else this.mode = "AND";
      this.emitChange();
      return false;
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        mj_axios
          .get(this.load_items_url)
          .then((response) => {
            this.items = response.data.data;

            resolve();

            // for (let i = 0; i < 44; i++) this.items.push(this.items[0]);
          })
          .catch(() => {
            this.error_text = "Errore nel caricamento dati";
            reject();
          })
          .then(() => {
            this.showLoader = false;
          });
      });
    },
    emitChange: function () {
      // const data = {"selected": this.selected, "mode":this.mode};
      const selected = this.selected.map((item) => item[this.item_value_field]);
      const data = {
        name: this.filter_name,
        type: this.mode,
        val: selected,
        glue: this.glue_data ? this.glue_data.toLowerCase() : this.glue_data,
      };
      if (this.relation_field_name) {
        data.relation_field_name = this.relation_field_name;
      }
      this.$emit("change", data);
    },
  },
  watch: {
    silent_reset: function () {
      this.selected = [];
      //this.emitChange(); non emette cambiamento ci pensa il parent o filtergroup
    },
  },
};
</script>