<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy" >
      <v-container>
        <div>Utente {{ $route.params.id }}</div>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="form.id.val" label="Id Utente" disabled required></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="form.name.val" :rules="[rules.required]" label="Nome Utente"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="form.email.val" :rules="[rules.required]" label="Email"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.password.val"
              type="password"
              :rules="editMode ? [] : [rules.required]"
              label="Password"
              autocomplete='off'
              persistent-hint
              hint="Password non registrata nel sistema per motivi di sicurezza. Se vuoi cambiarla scrivi la nuova password nel campo di testo."
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <!-- filter è un workaround -->
            <v-autocomplete
              v-model="form.role.val"
              item-text="name"
              item-value="id"
              :items="this.selects.role"
              cache-items
              return-object
              label="Ruoli"
            ></v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-switch v-model="form.active.val" label="Utente Attivo"></v-switch>
          </v-col>
        </v-row>

          <MjSchedaToolbar
          :error_show="error_show"
          :error_text="error_text"
          :edit_not_saved="edit_not_saved"
          :edit_mode="editMode"
          :new_button_visibile="true"
          :restore_item_show="restore_item_show"
          v-on:new-item-click="newItem()"
          v-on:save-item-click="saveItem()"
          v-on:go-back-click="goBack()"
          v-on:restore-item-click="restoreItem()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";

export default {
  name: "SchedaUserView",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: { MjSchedaToolbar },

  data: () => ({
    form: {
      id: { type: "integer", val: "", default_val: "" },
      name: { type: "string", val: "" },
      email: { type: "string", val: "" },
      password: { type: "string", val: "" },
      active: { type: "boolean", val: "" },
      role: {
        type: "object",
        apiUrl: "/api/user/roles",
        loadSelect: true,
        val: { name: "", id: "" }
      }
    },
    apiUrl: "/api/user/users"
  }),

  methods: {}
};
</script>
