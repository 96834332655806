const mj_color_utils = {
    /**
     * Dato un colore in formato #hex e un ammontare positivo o negativo restituisce il colore +chiaro o +scuro
     * @param color
     * @param amount
     * @returns {string}
     */
    adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}
   
      
}

export default  mj_color_utils;