/*
La  Booking View serve a gestire la fase iniziale di prenotazione in cui si inseriscono le varie opzioni.

La prima scelta sul tipo di "servizio" da prenotare arriva dalle view precedenti (es. parametro product_cod direttamente dentro la rotta) in cui si determina se si tratta di uno dei seguenti casi

- noleggio kart
- noleggio moto
- privati kart
- privati moto
- competizione

a seconda dei casi vengono mostrate più o meno v-card o step.
Opzionalmente si può passare il parametro :step (come extra path) per determinare in quale step deve trovarsi lo v-stepper al caricamento.

----
Premesso che un ProductItem (ovvero prodotto finale) è composto dalla combinazione di
ProductInfo (Tipo Prodotto) +
ProductCategory+
ProductSessionType+

Sul mounted vengono caricati tutti i product items di una determinata tipologia di prodotto.
Infatti al caricamento mediante link viene passato nella route il cod del productInfo che ci interessa
(questo infatti serve a identificare a quale tipo prodotto ci riferiamo: noleggio, privati, competizione etc.)
Questi dati finiscono nella struttura this.productItems.

Sul mounted carichiamo anche i tipi di pagamento disponibili che serviranno nella fase 4.
Infatti sugli eis caricati nella fase 3 abbiamo i cod dei pagamenti accettati, le info (testo icone etc. vengono caricate tramite loadPaymentsTypes sul mounted)

Mediante le computed otteniamo
1) Le info prodotto, (basta estrarre il primo ProductInfo dell'array dei productItems) in quanto sono tutti uguali
2) le possibili categorie per i productItems caricati.
3) le session types per una determinata category scelta

-----

Lo step 3 carica il calendario indicando quale prodotto / sessione si è scelto.


INSERIMENTO PRENOTAZIONE
I casi di inserimento dipendono dal metodo di pagamento scelto

Cash e Prepagato sono simili, con la differenza che il secondo fallisce in caso di credito insufficiente.
Su conferma dell'utente viene chiamato il controller insertBooking..

Con pagamento online, invece viene utilizzato l'MjStripeDialog passando il product_item_id che in questo caso corrisponde all'selected_eis.key
nel purchase_additional_data vengono inviati parametri aggiuntivi (nota, n_partecipanti, nome team etc.)
L'MjStripeDialog emette l'evento payment Done che genera semplicemente un showConfirmDialog. (FINE)
L'insertBooking viene effettuato dal backend utilizzando il listener che gestisce l'evento "Charging di tipo purchase".

L'insertBooking ha sempre bisogno dell'eis_relocated (o quella di default di agenda)
Nel caso di Pagamento Online invece, il purchase_item_id corrisponde a quello originale, ma nel purchase_additional_data dobbiamo inviare anche il relocated.
Il primo infatti serve per estrarre il prezzo, il secondo in fase di insertBooking quando viene scatenato l'evento Charged..


ALTRE NOTE
----------------------
Il metodo di pagamento "In Cassa" è sempre attivo, per evitare che se l'admin dimentica a configurare i sistemi di pagamento non si possa prenotare

*/

<template>
  <v-container fluid>
    <v-progress-linear v-show="loading > 0" indeterminate></v-progress-linear>
    <v-card-title class="overline font-weight-bold grey darken-3 py-0"
                  style="border-top-left-radius: 5px;border-top-right-radius:5px">
      <v-icon class="mr-2">mdi-go-kart</v-icon>
      {{ product_info.title }}
    </v-card-title>

    <v-stepper v-model="booking_stepper" style="border-top-left-radius: 0px; border-top-right-radius: 0px  ">
      <v-stepper-header>
        <v-stepper-step @click="booking_stepper = 1" step="1" :complete="booking_stepper > 1">Categoria<small
            class="mt-1">{{ product_info.title }}</small></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step @click="booking_stepper = 2" step="2" :complete="booking_stepper > 2">Tipo Sessione<small
            class="mt-1">{{ this.session_type_selected ? this.session_type_selected.title : '' }}</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step @click="booking_stepper = 3; " step="3"
                        :complete="selected_date != null && selected_eis != null">
          Calendario<small class="mt-1" v-if="this.selected_date">{{
            moment(this.selected_date).format('DD-MM-YYYY')
          }}</small></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step @click="booking_stepper = 4" step="4" :complete="booking_stepper > 4">Conferma</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="px-0 py-0">
          <v-card>
            <v-card-actions>
              <v-item-group
                  class="mt-3 "
                  v-if="available_categories.length > 0"
                  v-model="category_selected"
                  active-class="primary accent-4"
                  mandatory
                  @change="categoryChange"
                  style="width: 100%"
                  return-object
              >
                <v-row justify="center">
                  <v-col cols="4" sm="3" md="2" v-for="available_categories in available_categories"
                         :key="available_categories.id">
                    <v-item v-slot="{active,toggle}" :value="available_categories">
                      <v-badge v-if="available_categories.best_seller" overlap offset-x="104" offset-y="8" bottom
                               color="secondary"
                               style="border-radius:5px">
                        <v-btn  @click="toggle" class="option_btn" :color="(active)? 'primary' : ''">
                          {{ available_categories.title }}
                        </v-btn>
                        <template v-slot:badge><span class="small font-weight-bold">Più Venduto</span></template>
                      </v-badge>
                      <v-btn  v-else class='option_btn' @click="toggle">{{ available_categories.title }}</v-btn>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-card-actions>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-img
                      width="320"
                      lazy-src="@/assets/images/no_image.png"
                      :src="this.category_selected ? this.category_selected.image_url : ''"
                  ></v-img>
                </v-col>
                <v-col class="">
                  <h4>{{ product_info.title }}</h4>
                  <h5>Età Minima: {{ this.category_selected ? this.category_selected.minimum_age : 'N.D.' }} anni</h5>
                  <v-rating color="primary darken-2" dense full-icon="mdi-racing-helmet" empty-icon="mdi-circle-small"
                            readonly
                            :value=" this.category_selected ? this.category_selected.difficulty_level : 0"></v-rating>
                  <p class="small mt-2">{{ this.category_selected ? this.category_selected.description : 'N.D.' }}</p>
                  <p class="overline"> {{ this.category_selected ? this.category_selected.technical_note : 'N.D.' }}</p>
                  <div>{{ product_info.description }}</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mb-3 mr-3" color="secondary" @click="booking_stepper=2">Avanti
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2" class="px-0 py-0">
          <v-card>
            <v-card-actions v-if="available_session_types && available_session_types.length>0">
              <v-item-group
                  v-model="session_type_selected"
                  active-class="primary accent-4"
                  mandatory
                  style="width: 100%;"
              >
                <v-row justify="center">
                  <v-col cols="4" sm="3" md="2" v-for="session_type in available_session_types" :key="session_type.id">
                    <v-item v-slot="{active,toggle}" :value="session_type">
                      <v-badge v-if="session_type.best_seller" overlap offset-x="104" offset-y="8" bottom
                               color="secondary"
                               style="border-radius:5px">
                        <v-btn @click="toggle" class="option_btn" :color="(active)? 'primary' : ''">{{
                            session_type.title
                          }}
                        </v-btn>
                        <template v-slot:badge><span class="small font-weight-bold">Più Venduto</span></template>
                      </v-badge>
                      <v-btn v-else class='option_btn' @click="toggle">{{ session_type.title }}</v-btn>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-card-actions>

            <v-card-title v-else class="text-center">Buone Notizie!</v-card-title>

            <v-card-text v-if="available_session_types && available_session_types.length>0">
              <v-row>
                <v-col class="">
                  <v-img
                      width="320"
                      lazy-src="@/assets/images/no_image.png"
                      :src="session_type_selected.image_url"
                  ></v-img>
                </v-col>
                <v-col class="">
                  <h4>{{ session_type_selected.title }}</h4>
                  <p class="small mt-2">{{ session_type_selected.description }}</p>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-else class="mb-6">
              Per questo Prodotto non è prevista la scelta del tipo di sessione, clicca su avanti per procedere!
            </v-card-text>

            <v-card-actions>
              <v-btn color="white" icon class="mb-3 ml-3" @click="booking_stepper=1">
                <v-icon left>mdi-arrow-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="mb-3 mr-3" color="secondary" @click="booking_stepper=3">Avanti
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-stepper-content>
        <v-stepper-content step="3" class="px-0 py-0">
          <v-card>
            <v-card-title>{{ product_info.title }} {{ category_selected.title }} {{ session_type_selected.title }}
            </v-card-title>
            <v-card-text>
              <MjDaysBar @change="dateChange"/>
              <v-slider
                  class="ml-3 mt-5"
                  v-model="n_partecipants"
                  @change="loadAvailableEventIntervalSetup"
                  hint="Numero Partecipanti"
                  :persistent-hint="true"
                  max="18"
                  min="1"
                  thumb-label="always"
                  thumb-size="28"
                  append-icon="mdi-account-multiple"
              ></v-slider>
              <div v-if="available_eis == null || available_eis.length == 0" class="text-center mt-12">
                <h2 class="warning--text">Nessuna Disponibilità!</h2>
                <div class="grey--text">Cambia la data o diminuisci il numero di partecipanti</div>
              </div>
              <v-item-group
                  v-else
                  v-model="selected_eis"
                  active-class=" "
                  @change="changeSelectedEis"
                  mandatory
              >
                <v-row justify="start" class="mx-auto">
                  <v-col class="text-center" cols="auto" v-for="eis in available_eis" :key="eis.id">

                    <v-item v-slot="{toggle}" :value="eis">
                      <v-card @click="toggle" rounded>
                        <v-card-title class="blue-grey--text justify-center pb-1">
                          <v-sheet rounded class="px-2">{{ (moment(eis.start)).format('HH:mm') }}</v-sheet>
                        </v-card-title>
                        <v-card-subtitle v-show="eis.title" class="pt-1">{{ eis.title }}</v-card-subtitle>
                        <v-divider class="pb-0"></v-divider>
                        <v-card-text>
                          <v-sheet class="font-weight-bold" rounded>{{ price_formatter.format(eis.price) }}</v-sheet>
                          <div class="x-small">{{ (moment(eis.start)).locale('it').format('dddd') }}</div>
                          <v-sheet v-if="eis.discount > 0" color="primary" class="mb-1" rounded> -
                            {{ percent_formatter.format(eis.discount / 100) }}
                          </v-sheet>
                          <v-sheet v-if="eis.discount_prepaid > 0" color="secondary" rounded>-
                            {{ percent_formatter.format(eis.discount_prepaid / 100) }}
                          </v-sheet>

                        </v-card-text>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>


              <div class="mt-16 caption" v-if="this.selected_eis">
                <v-divider class="mb-1"></v-divider>

                <span>Prezzo per Partecipante: {{ price_formatter.format(selected_eis.price) }}</span>
                <span v-if="selected_eis && selected_eis.discount > 0">/ Sconto: <span
                    class="font-weight-bold">{{ percent_formatter.format(selected_eis.discount / 100) }}</span></span>
                <span v-if="selected_eis && selected_eis.discount_prepaid >0 ">/ Sconto per Prepagato: <span
                    class="font-weight-bold">{{ percent_formatter.format(selected_eis.discount_prepaid / 100) }}</span></span>
              </div>

            </v-card-text>
            <v-card-actions>
              <v-btn color="white" icon class="mb-3 ml-3" @click="booking_stepper=2">
                <v-icon left>mdi-arrow-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" class="mb-3 mr-3" @click="booking_stepper=4">Avanti
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4" class="px-0 py-0">
          <v-card>
            <v-card-text v-if="!selected_eis">
              <div class="title">Hey! Dimentichi qualcosa?</div>
              <p>Per poter procedere con la prenotazione devi prima procedere con i passaggi successivi</p>
              <p>Torna indietro e completa i passaggi precedenti prima di ritornare. Usa la freccia in basso a
                destra!</p>
            </v-card-text>
            <v-card-text v-else>
              <div class="title">Riepilogo</div>
              <div class="overline font-weight-bold primary--text ">{{ product_info.title }} {{
                  category_selected.title
                }} {{ session_type_selected.title }}
              </div>
              <div class="subtitle-2">Prenotazione per: <span class="font-weight-bold">{{
                  moment(selected_eis.start).format('DD-MM-YYYY')
                }} alle {{ moment(selected_eis.start).format('HH:mm') }}</span></div>
              <div class="subtitle-2">Numero Partecipanti:<span
                  class="font-weight-bold"> {{ this.n_partecipants }}</span></div>
              <br>
              <div class="subtitle-1">
                Prezzo Iniziale per Partecipante: <span class="font-weight-bold primary--text">{{
                  price_formatter.format(selected_eis.price)
                }}</span>
                <span v-if="selected_eis.discount && selected_eis.discount > 0 "><br> Sconto: <span
                    class="font-weight-bold primary--text">-{{
                    percent_formatter.format(selected_eis.discount / 100)
                  }}</span></span>
                <span v-if="selected_eis.discount_prepaid && selected_eis.discount_prepaid > 0 "><br> Sconto per Prepagato: <span
                    class="font-weight-bold primary--text">-{{
                    percent_formatter.format(selected_eis.discount_prepaid / 100)
                  }}</span></span>
              </div>

              <v-radio-group v-if="this.pay_with_prepaid_forced === false" mandatory v-model="selected_payment_type"
                             label="Metodo di pagamento utilizzato:">
                <!--In cassa è sempre attivo per evitare che una dimenticanza dell'admin crei impossibilità a prenotare-->
                <v-radio
                    :disabled="!selected_eis.accepted_payment_type_cods.some(accepted_payment_type_cod => accepted_payment_type_cod === payment_type.cod) "
                    :label="payment_type.description" v-for="payment_type in payment_types" :key="payment_type.cod"
                    :value="payment_type.cod"
                >
                </v-radio>
              </v-radio-group>

              <div>Il tuo credito attuale è
                <span>{{ price_formatter.format(this.$store.state.token_obj.credit) }}</span>. <a
                    href="/#/utente/il_mio_credito" target="_blank" class="secondary--text">Clicca qui per
                  ricaricare</a></div>
              <v-text-field v-if='is_competizione' v-model="booking_team_name" class="mt-6" label="Nome Team"> </v-text-field>
              <v-textarea class="mt-6" v-model="booking_user_note" rows="2" label="Nota per l'esercente"
                          placeholder="Scrivi eventuali note aggiuntive riguardante la tua prenotazione"></v-textarea>

              <div class="title mt-4">

                Prezzo Finale: <span v-if="this.final_price != -1" class="font-weight-bold primary--text">{{
                  price_formatter.format( this.tot_n_partecipants_final_price)
                }}<br>
                <span class="overline">
                  Totale per {{this.n_partecipants}} Partecipant{{(this.n_partecipants > 1) ? 'i':'e'}}

                </span>
              </span>

                <span v-else>Scegli metodo di pagamento</span>
              </div>


            </v-card-text>
            <v-card-actions>
              <v-btn color="white" icon @click="booking_stepper=3">
                <v-icon left>mdi-arrow-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="confirmBooking">Conferma
                <v-icon right>mdi-check-circle-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <MjStripeDialog
        v-if="this.selected_eis"
        v-model="show_stripe_dialog"
        :price="parseInt(this.tot_n_partecipants_final_price * 100)"
        :purchasing_item_id="this.selected_eis.original_key ? this.selected_eis.original_key : this.selected_eis.key"
        :purchase_item_quantity="this.n_partecipants"
        charge_type="PURCHASE"
        :purchase_additional_data='{"n_partecipants":this.n_partecipants,"note":this.booking_user_note,"team_name":this.booking_team_name, "eis_relocated_key":this.selected_eis.key}'
        dialog_title="Pagamento Online"
        @payment-cancel="show_stripe_dialog=false"
        @payment-done="show_stripe_dialog=false; show_booking_confirmed = true"
    />
    <MjConfirmDialog dialog_title="Conferma Prenotazione"
                     dialog_text=""
                     fullscreen
                     confirm_button_text="Ho Capito"
                     :cancel_button_visible="false"
                     @confirm-from-dialog="$router.replace('/Menu')"
                     v-model="show_booking_confirmed">
      <slot>
        <div class="text-center">
          <v-icon class="my-3" x-large color="primary">mdi-thumb-up-outline</v-icon>
          <h2>La tua prenotazione per il <span
              v-if="selected_eis">{{ moment(selected_eis.start).format('DD-MM-YYYY HH:mm') }} </span> è stata
            confermata!</h2>
          <h3 class="my-3">Riceverai a breve un'email di conferma</h3>
        </div>
      </slot>
    </MjConfirmDialog>

  </v-container>
</template>
<style>
.option_btn {
  min-width: 100px !important;
}
</style>
<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import moment from "moment";
import mj_axios from "@/mj_axios";
import MjDaysBar from "@/components/calendar/MjDaysBar.vue";
import MjStripeDialog from "@/components/payment/MjStripeDialog";
import MjConfirmDialog from "@/components/MjConfirmDialog";


export default {
  name: "CustomBookingView",
  components: {MjConfirmDialog, MjDaysBar, MjStripeDialog},
  mixins: [ValidationRulesMixin],
  computed: {
    product_cod: function () {
      return this.$route.meta.product_cod
    },

    product_info: function () {
      let product_info = {};
      if (this.product_items != null && this.product_items.length > 0)
        product_info = this.product_items[0].product_info
      return product_info;
    },

    available_categories: function () {
      //in tutti i product items estra le product category, ed elimina i doppioni
      const available_categories = this.product_items.map((pi) => pi.product_category).filter((cat, index, self) => index === self.findIndex((item) => (cat != null) && (item["cod"] === cat["cod"])));
      return available_categories;
    },
    available_session_types: function () {
      let available_session_types = []
      if (this.category_selected) {
        const category_selected_id = this.category_selected.id;
        const product_items_filtered = this.product_items.filter((pi) => pi.product_category.id === category_selected_id && pi.product_session_type)

        if (product_items_filtered && product_items_filtered.length > 0) {
          available_session_types = product_items_filtered.map((pi) => pi.product_session_type);
        }

        available_session_types = this.product_items.filter((pi) => pi.product_category.id === category_selected_id && pi.product_session_type).map((pi) => pi.product_session_type);
      }

      return available_session_types;
    },
    product_item_selected: function () {
      let product_item = null;
      let predicate = null;
      //se ho sia una categoria selezionata che una session selezionata filtro su entrambi i product item
      if (this.category_selected != null && Object.keys(this.category_selected).length > 0 && this.session_type_selected != null && Object.keys(this.session_type_selected).length) {

        predicate = (pi) => (pi.product_category.id === this.category_selected.id) && (pi.product_session_type.id === this.session_type_selected.id);
      }
      //essendo ramo else vuol dire che se passa la session_type_selected non è definita
      else if (this.category_selected != null && Object.keys(this.category_selected).length > 0) {

        predicate = (pi) => (pi.product_category.id === this.category_selected.id);
      } else if (this.session_type_selected != null && Object.keys(this.session_type_selected).length) {
        predicate = (pi) => (pi.product_session_type.id === this.session_type_selected.id);
      }

      if (predicate) {
        product_item = this.product_items.filter(predicate);
      }
      return product_item;
    },

    is_noleggio_kart() {
      return this.product_cod === 'NK';
    },
    is_competizione(){
      return this.product_cod === 'CO'
    }

    ,
    selected_eis_date_formatted() {
      if (this.selected_eis) {
        return moment(this.selected_eis.start_date).isValid() ? moment(this.selected_eis.start_date).format('DD/MM/YYYY') : "Data Non Valida";
      } else return 'N.D.';
    },
    selected_eis_time_formatted() {
      if (this.selected_eis) {
        return moment(this.selected_eis.start_date).isValid() ? moment(this.selected_eis.start_date).format('HH:mm') : "";
      } else return 'N.D.';
    },
    //prezzo già scontato
    final_price() {
      let final_price = -1;
      if (this.selected_eis && this.selected_payment_type) {
        // console.log(this.selected_payment_type + ' final price');
        final_price = this.selected_eis.price;
        let discount = null;
        if (this.selected_payment_type === 'PP' && this.selected_eis.discount_prepaid > 0)  {
            discount = this.selected_eis.discount_prepaid;
        } else if (this.selected_payment_type !== 'PP' && this.selected_eis.discount > 0) {
            discount = this.selected_eis.discount;
        }

        if (discount) {
          final_price = Math.round(final_price - (final_price * discount / 100))
        }
      }
      // console.log('computo final price: ' + final_price + 'selected payment type ' + this.selected_payment_type);
      return final_price;
    },
    //prezzo finale scontato moltiplicato per il numero di partecipanti
    tot_n_partecipants_final_price(){
      return this.final_price*this.n_partecipants;
    },
    //calcolo del prezzo finale nel caso di pagamento prepagato
    prepaid_final_price() {
      let prepaid_final_price = this.selected_eis.price;
      if (this.selected_eis.discount_prepaid > 0) {
        prepaid_final_price = Math.round(prepaid_final_price - (prepaid_final_price * this.selected_eis.discount_prepaid / 100));
      }
      return prepaid_final_price;
    },
    tot_n_partecipants_prepaid_final_price(){
      return this.prepaid_final_price*this.n_partecipants;
    }
    ,
    //sotto certe condizioni obblighiamo l'utente a pagare con il prepagato
    pay_with_prepaid_forced() {
      // console.log('computo pay_with_prepaid_forced: credito ' + this.$store.state.token_obj.credit + "prepaid_final_price " + this.prepaid_final_price);

      return this.selected_eis && parseFloat(this.$store.state.token_obj.credit) >= (this.tot_n_partecipants_prepaid_final_price) && this.selected_eis.accepted_payment_type_cods.includes("PP");
    }
  },
  beforeMount() {
    this.booking_stepper = this.$route.params.step || 1;
    this.loadProductItems();
    this.loadPaymentTypes();
    this.reloadLoggedUser();

  },
  mounted() {
    //utilizzato per fare in modo che se l'utente abbandona la pagina per caricare il credito, lo ritrova aggiornato al ritorno
    //non uso funzione anonima altrimenti non riesco nel removeEventListener
    window.addEventListener('focus',  this.reloadLoggedUser);
  },
  destroyed() {
    window.removeEventListener('focus',  this.reloadLoggedUser);
  },
  data: () => (
      {
        booking_stepper: 1,
        product_items: [],
        category_selected: {},
        session_type_selected: {},

        available_eis: [],
        selected_eis: null,
        selected_date: null,
        selected_payment_type: null,
        n_partecipants: 1,
        booking_team_name: '',
        booking_user_note: '',
        show_stripe_dialog: false,
        show_booking_confirmed: false,
        loading: 0,

        payment_types: [/*caricati tramite ajax -> {text: 'Prepagato', value: 'PP', icon: 'mdi-piggy-bank-outline'},*/],

        price_formatter: new Intl.NumberFormat('IT', {
          style: 'currency',
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),
        percent_formatter: new Intl.NumberFormat('IT', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }),

      }
  ),
  methods: {

    //per uso nei tag del template
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },

    dateChange: function (val) {
      this.selected_date = val.date;
      this.loadAvailableEventIntervalSetup();
    },

    //-------------------------TIPOLOGIE PAGAMENTO-----------------
    loadPaymentTypes() {
      this.loading++;
      const axios_promise = mj_axios.get('/api/payment_type');
      axios_promise.then(response => {
        this.payment_types = response.data.data;
      }).catch(() => {

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento delle tipologie di pagamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;

    },

    //-------------------------PRODUCT ITEMS-----------------------
    loadProductItems() {

      this.loading++;
      const axios_promise = mj_axios.get(`/api/utente/product_items/${this.product_cod}`);
      axios_promise.then(response => {
        this.product_items = response.data.data;
      }).catch(() => {

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;

      // if (this.is_noleggio_kart) {
      //   if (this.category_selected == 0) {
      //     this.product_info = {
      //       id: 1,
      //       title: 'Kart Senior',
      //       description: 'descrizione del prodotto come se fosse antani',
      //       min_age: 15,
      //       image: 'https://www-2556b.bookeo.com/bookeo/cfile/42556WN3PFC16DDAD81BF4/1588068535128_UTTXRHTJMYRCE7EAK4RCLJJA7RHAUHWN_840_533.jpg',
      //       technical_note: 'Nota Tecnica..',
      //       generic_note: 'Note Generiche',
      //       difficulty: 4
      //     };
      //   } else if (this.category_selected == 1) {
      //     this.product_info = {
      //       id: 1,
      //       title: 'Kart Junior',
      //       description: 'descrizione del prodotto come se fosse antani',
      //       min_age: 15,
      //       image: 'https://www-2556b.bookeo.com/bookeo/cfile/42556WN3PFC16DDAD81BF4/1591709936109_4RMK9AU9NCCNUX99W6RJXXWELHAANFUU_1000_500.jpg',
      //       technical_note: 'Nota Tecnica..',
      //       generic_note: 'Note Generiche',
      //       difficulty: 1
      //     }
      //   }
      // } else {
      //   this.product_info = {
      //     id: 1,
      //     title: 'Noleggio Moto',
      //     description: 'descrizione del prodotto come se fosse antani',
      //     min_age: 15,
      //     image: 'https://www.misanino.it/wp-content/uploads/2018/10/PISTA-OHVALE.jpg',
      //     technical_note: 'Nota Tecnica..',
      //     generic_note: 'Note Generiche',
      //     difficulty: 1
      //   }
      // }

    },

    categoryChange() {


    },

    // async loadProductAvailableCategory() {
    //   this.available_categories = [
    //     {id: 0, name: 'Senior', best_seller: true},
    //     {id: 1, name: 'Junior', best_seller: false},
    //     {id: 2, name: 'Race', best_seller: false},
    //   ];
    // },
    // async loadSessionAvailableType() {
    //   if (this.product_cod === 'competizione') {
    //     this.available_session_types = [
    //       {id: 0, name: 'Endurance', best_seller: false},
    //       {id: 1, name: 'Sprint', best_seller: true},
    //       {id: 2, name: 'Iron', best_seller: false},
    //     ];
    //   } else {
    //     this.available_session_types = [
    //       {id: 0, name: '10 Min', best_seller: false},
    //       {id: 1, name: 'Mini GP', best_seller: true},
    //       {id: 2, name: 'Grand Prix', best_seller: false},
    //     ];
    //   }
    // },
    // async loadSessionTypeInfos() {
    //
    //   this.session_type_info = {
    //     title: '10 Minuti',
    //     description: 'descrizione subprodotto come se fosse sfiriguti',
    //     technical_note: 'Nota Tecnica..',
    //     generic_note: 'Note Generiche',
    //     price: 10.2,
    //     image: 'https://www.misanino.it/wp-content/uploads/2018/10/il-tracciato.jpg',
    //     duration_min: 10
    //
    //   }
    // },

    /*-----------------------EIS FUNCTIONS-------------------*/
    loadAvailableEventIntervalSetup() {

      if (this.product_item_selected && this.product_item_selected.length > 1) {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Prima di consultare il calendario devi effettuare le scelte nei passaggi precedenti..",
          visible: true,
        });
      } else if (this.product_item_selected && this.selected_date) {
        // console.log('loadAvailSlot ' + this.selected_date + " " + this.product_item_selected[0].key);

        this.selected_eis = null;

        this.loading++;

        let selected_date_moment = moment(this.selected_date);
        const now = moment();

        //se la data scelta è quella odierna devo mostrare solo a partire dall'ora attuale
        if (selected_date_moment.isSame(now, 'day')) {

          selected_date_moment = now;
        }

        //from parte dall'inzio della giornata, e to alle 4.00 del mattino del giorno dopo per mostrare gli orari notturni considerati spesso dall'utente come stessa giornata
        const params = {
          "calendar_type": "agenda",
          "state": 1, //eis attivi
          "calendar_from": selected_date_moment.format('YYYY-MM-DD HH:mm:ss'),
          "calendar_to": selected_date_moment.add(1, 'day').format('YYYY-MM-DD 04:00:00'),
          "partecipants_count": this.n_partecipants,
          "product_item_id": this.product_item_selected[0].id,
        };

        const axios_promise = mj_axios.get('/api/utente/event_interval_setup', {params: params});
        axios_promise.then(response => {
          this.available_eis = response.data.data;
        }).catch(() => {
          this.available_eis = [];
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di caricamento degli slot, prima di proseguire, se il problema persiste contattare l'assistenza tecnica.",
            visible: true,
          });
        }).then(() => {
          this.loading--;
        });
        return axios_promise;

        // console.log(JSON.stringify(this.product_item_selected));

        //   if (this.selected_date === moment().format('YYYY-MM-DD')) //test data condition to be delete
        //     this.available_eis = [];
        //   else
        //     this.available_eis = [
        //       {id: 130, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 2, start_date: '2020-12-31 11:15:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 3, start_date: '2020-12-31 11:30:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 4, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 5, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 6, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 7, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 8, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 9, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 10, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 11, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 12, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 13, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //       {id: 14, start_date: '2020-12-31 11:00:00', end_date: '2020-12-31 11:15:00', free: 10},
        //     ]
        // }
      }
    },
    changeSelectedEis() {

      // console.log('change selected eis ' + this.$store.state.token_obj.credit + ' ' + this.final_price);
      //Se l'utente ha credito sufficiente e il metodo prepagato (PP) è supportato dall'eis selezionato allora impostalo come scelta..
      if (this.pay_with_prepaid_forced) {
        this.selected_payment_type = "PP";
      } else {
        this.selected_payment_type = "CA";
      }
    },
    /*-----------------------END EIS FUNCTIONS-------------------*/

    /*-----------------------BOOKING FUNCTIONS-------------------*/
    confirmBooking() {

      if (this.selected_payment_type === 'ON') {
        this.show_stripe_dialog = true;
      } else {
        const params = {
          n_partecipants: this.n_partecipants,
          start: this.selected_eis.start,
          end: this.selected_eis.end,
          eis_key: this.selected_eis.key,
          selected_payment_type_cod: this.selected_payment_type,
          note: this.booking_user_note,
          team_name: this.booking_team_name
        };


        this.loading++;

        const axios_promise = mj_axios.post('/api/utente/insert_booking', params);
        axios_promise.then(() => {

          this.reloadLoggedUser();
          this.show_booking_confirmed = true;
        }).catch((e) => {

          let message = "";
          if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
          }
          let text = `Errore in fase di prenotazione, se il problema persiste contatta l'assistenza.. ${message}`;
          //se 402 mostro solo il message
          if (e.response.status === 402) {
            text = message;
          }

          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: text,
            visible: true,
          });

        }).then(() => {
          this.loading--;
        });
        return axios_promise;
      }

    },
    /*-----------------------END BOOKING FUNCTIONS-------------------*/

    /*-----------------------MISC FUNCTIONS-------------------*/
    //necessaria per fare add e remove listener, con la funzione anonima il remove non funzionerebbe
    reloadLoggedUser(){
      this.$store.dispatch("reloadLoggedUser");
    }
  },


  watch: {
    //usato il watch perchè @change su v-stepper sembra non funzionare
    booking_stepper(val) {
      if (val == 3) this.loadAvailableEventIntervalSetup();
    }
  }
};
</script>
