<template>
  <v-container>

    <MjTableItems
        ref="table_booking"
        model-name-prop="Booking"
        resource-collection-name="BookingForUserResourceListCollection"
        apiUrl="/api/utente/list/"
        sort_by="Data"
        :customEditItem="customEditItem"
        :sort_desc="true"
        :loadItemsOnCreated="true"
        :showWithTrashed="false"
        :enablePrint="false"
    >

      <!--      <template slot="mj_filters_section">-->
      <!--        <MjFilterGroup-->
      <!--            :expand_filter="false"-->

      <!--            class="mt-0 px-2"-->
      <!--            @reset-all="-->
      <!--            $refs.table_booking.filters = [];-->
      <!--            $refs.table_booking.loadItems(true);-->
      <!--          "-->
      <!--        >-->

      <!--          <template slot="filters" slot-scope="props">-->
      <!--            <v-row>-->
      <!--              <v-col>-->
      <!--                <MjDateIntervalFilter-->
      <!--                    label-from="Da"-->
      <!--                    label-to="A"-->
      <!--                    time-model-format="HH:mm:ss"-->
      <!--                    time-display-format="HH:mm"-->
      <!--                    date-model-format="YYYY-MM-DD"-->
      <!--                    date-display-format="DD/MM/YYYY"-->
      <!--                    mode="date"-->
      <!--                    glue="and"-->
      <!--                    @change="updateFilters"-->
      <!--                    :silent_reset="props.reset_all"-->
      <!--                    filter_name="start"-->
      <!--                    :valueFrom="filter_from"-->
      <!--                    :valueTo="filter_to"-->
      <!--                />-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--            <v-row align="center"  >-->
      <!--              <v-col cols="6">-->
      <!--                <MjComboBoxFilter-->
      <!--                    label="Prodotto"-->
      <!--                    load_items_url="/api/list/ProductItem/ProductItemResource4SelectCollection"-->
      <!--                    filter_name="product_item_cod"-->
      <!--                    :item_description_field="(item) => `${item.cod} - ${item.description}`"-->
      <!--                    item_value_field="cod"-->
      <!--                    glue="and"-->
      <!--                    @change="updateFilters"-->
      <!--                    :silent_reset="props.reset_all"-->
      <!--                />-->
      <!--              </v-col>-->
      <!--              <v-col cols="6">-->
      <!--                <MjToggleFilter-->
      <!--                    label="Stato Prenotazione"-->
      <!--                    small-->
      <!--                    :items_prop="[{description:'Inserita',value:1}, {description:'Annullata', value:-1}]"-->
      <!--                    filter_name="booking_status"-->
      <!--                    :item_description_field="(item) => `${item.description}`"-->
      <!--                    item_value_field="value"-->
      <!--                    glue="and"-->
      <!--                    @change="updateFilters"-->
      <!--                    :silent_reset="props.reset_all"-->
      <!--                />-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </template>-->
      <!--        </MjFilterGroup>-->
      <!--      </template>-->
    </MjTableItems>
    <MjConfirmDialog v-if="selected_row!=null" v-model="show_selected_row_dialog"
                     @cancel-from-dialog="show_selected_row_dialog=false"
                     @confirm-from-dialog="confirm_cancel_booking_show = true"
                     dialog_title="Riepilogo Prenotazione" cancel_button_text="Indietro"
                     confirm_button_text="Annulla Prenotazione"
    >
      <div>Data:<span class="font-weight-bold"> {{ this.selected_row.Data }}</span></div>
      <div class="mt-1">Prodotto: <span class="font-weight-bold">{{ this.selected_row.Prodotto }}</span></div>
      <div class="mt-1">Partecipanti: <span class="font-weight-bold">{{ this.selected_row["P."] }}</span></div>
      <div class="mt-1">Modalità Pagamento: <span class="font-weight-bold">{{ this.selected_row["Mod Pag"] }}</span>
      </div>
      <div class="mt-1 mb-4">Stato Pagamento: <span
          class="font-weight-bold">{{ this.selected_row["Pagato"].description }}</span></div>

    </MjConfirmDialog>

    <MjConfirmDialog v-if="selected_row!=null" v-model="confirm_cancel_booking_show"
                     @cancel-from-dialog="confirm_cancel_booking_show=false"
                     @confirm-from-dialog="cancelBooking();confirm_cancel_booking_show = false; show_selected_row_dialog = false"
                     dialog_title="Conferma Cancellazione" cancel_button_text="Non voglio annullare"
                     confirm_button_text="Confermo Annullamento"
    >
      Se confermi la tua prenotazione sarà annullata!
    </MjConfirmDialog>

  </v-container>
</template>

<script>

import MjTableItems from "@/components/MjTableItems";
import moment from "moment";
import MjConfirmDialog from "@/components/MjConfirmDialog";
import mj_axios from "@/mj_axios";
// import schedaMixin from "@/mixins/SchedaMixin";

export default {
  name: "BookingHistoryView",
  mixins: [],
  components: {
    MjConfirmDialog,
    MjTableItems/*, MjDateIntervalFilter, MjFilterGroup, MjToggleFilter, MjComboBoxFilter*/
  },
  data: () => ({
    filter_from: null,
    filter_to: null,
    selected_row: null,
    show_selected_row_dialog: false,
    confirm_cancel_booking_show: false,
  }),

  methods: {
    cancelBooking: function () {
      this.loading++;
      const axios_promise = mj_axios.post(`/api/utente/cancel_booking/${this.selected_row.id}`);
      axios_promise.then(() => {
        this.$refs.table_booking.loadItems(true);
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di Annullamento Prenotazione, se l'errore persiste contattare il supporto tecnico.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    },
    customEditItem: function (row) {
      this.selected_row = row;
      this.show_selected_row_dialog = true;
    },
    //ridefinito altrimenti non lo trova prima del rendering di TableItems
    //quindi uso il ref.table_booking per richiamarne l'implementazione effettiva
    updateFilters: function (data) {


      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        this.$refs.table_booking.updateFilters(data);
      })
    },
  },
  watch: {
    $route: {
      handler: function () {
        if (this.$route.query.date) {
          const date_moment = moment(this.$route.query.date);
          this.filter_from = date_moment.format('YYYY-MM-DD');
          this.filter_to = date_moment.format('YYYY-MM-DD');
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
