const mj_config = {
    isDevelopmentMode() {
        return (process.env.NODE_ENV === 'development')
    },
    getBaseUrl() {
        let baseUrl;
        if (this.isDevelopmentMode()) {
            baseUrl = `https://${window.location.hostname}:8433`;
        } else {
            baseUrl = `https://${window.location.hostname}`;
        }
        return baseUrl
    }
    ,
    //disabilitiamo o abilitiamo a livello globale il csv exporting 
    csv_global_export_enabled() {
        return false;
    },
    //stabilisco il tema di default (dark vs light)
    dark_mode_on_default() {
        return true;
    },
    //considero il tema memorizzato nello storage o il valore di default
    dark_mode_on() {
        if (window.localStorage.getItem('dark_mode_on') === 'true') {
            return true;
        } else if (window.localStorage.getItem('dark_mode_on') === 'false') {
            return false;
        } else {
            return this.dark_mode_on_default();
        }
    },
    toc_url() {
        return 'http://www.google.com'
    },
    privacy_url() {
        return 'http://www.google.com'
    },
    //indica se il sistema prevedere registrazione, recupero password.
    allow_registration() {
        return true;
    },
    //indica se l'utente ha un credito (ad es. prepagato)
    user_has_credit_enabled() {
        return true;
    },
    facebookLoginOn() {
        return true;
    },
    facebookAppId() {
        return '239189544526014';
    },
    facebookApiVer() {
        return "v13.0";
    },
    googleLoginOn() {
        return true;
    },
    googleClientId() {
        return "725122320368-3rskn8etc74qgujfbqoinmm3o50oq4j4.apps.googleusercontent.com";
    },
    googleSiteKey() {
        return '6LefsgsaAAAAAMmQ4AJp74cP9SMwkQSStSCLubKv';
    }, //usato per recaptcha
    stripePublicKey() {
        if (this.isDevelopmentMode())
            return 'pk_test_51I0oqCETqAGmW9JyV49XmI8yPj2AWk10bplWdeHHOeKMdmB1Cdbp2tqBsVxq2LNzyxh3eSYI5TV7KpL66gLka6g700BZTk8Uva';
        else
            // return 'pk_live_51I0oqCETqAGmW9Jy6yM74mBn4ka3RivWGNB3JHzHCEWFD1bGy7jL8JKAgcvBz6bICfeov4ladUlSe5ByEPuLHKqs009Ufc8cEd';
            return 'pk_test_51I0oqCETqAGmW9JyV49XmI8yPj2AWk10bplWdeHHOeKMdmB1Cdbp2tqBsVxq2LNzyxh3eSYI5TV7KpL66gLka6g700BZTk8Uva';
    }
}
export default mj_config;
