/*
Il componente serve a mostrare 7 giorni a partire da una data indicata dalle prop (di default è today)
mediante le freccette dx e sx si ci può spostare avanti e indietro nei giorni.

EMIT
- change con un oggetto che contiene diversi dati riguardati la data selezionata.


*/
<template>
  <v-container>
  <v-sheet class=" d-flex align-center" color="transparent">
    <v-btn icon @click="calendar_shift(-1)">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <div class="grow text-center">
    {{calendar_start_month_formatted}}
    </div>
    <v-btn icon @click="calendar_shift(1)">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </v-sheet>
  <v-sheet height="80" class="mb-3 d-flex align-center" color="transparent">

    <v-calendar :day-format="dayFormat" @click:day="clickDay" @click:date="clickDay" :show-month-on-first='false'
                locale="it" :weekdays="calendar_day_order" type="custom-weekly" :start="calendar_start_formatted"
                :end="calendar_end_formatted">

      <template v-slot:day-label="{day,date}">
        <v-btn small fab class="mb-2"
               :color="(selected_date_formatted === date  ? 'primary': (today_formatted === date) ? 'transparent' : '')">
          {{ day }}
        </v-btn>
      </template>
    </v-calendar>

  </v-sheet>
  </v-container>
</template>

<script>
import moment from "moment";
import mj_string_utils from "@/misc/mj_string_utils.js"

export default {
  name: "MjDaysBar",
  props: {startDate: {default: moment().format('YYYY-MM-DD')}},
  computed: {
    calendar_day_order: function () {
      //costruisce la prop weekdays [2,3,4,5,6,0,1]
      let calendar_day_order = [];
      let week_day = this.calendar_start.weekday();
      for (let i = 0; i < 7; i++) {
        if (week_day > 6) week_day = 0
        calendar_day_order.push(week_day);
        week_day++;
      }
      return calendar_day_order;
    },
    calendar_end: function () {
      return this.calendar_start.clone().add(6, 'days')
    },
    calendar_start_formatted: function () {
      return this.calendar_start.format('YYYY-MM-DD');
    },
    calendar_end_formatted: function () {
      return this.calendar_end.format('YYYY-MM-DD');
    },
    today_formatted: function () {
      return moment().format('YYYY-MM-DD')
    },
    calendar_start_month_formatted: function(){
      return mj_string_utils.capitalize(moment(this.calendar_start_formatted).locale('it').format('MMMM'));
    }
  },

  data: function () {
    return {
      calendar_start: moment(this.startDate),
      selected_date_formatted: null,
    }
  },
  methods: {
    //per uso nei tag del template
    moment: function (date_string,date_format) {
      return moment(date_string,date_format);
    },
    dayFormat: (date) => {
      const months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
      return `${date.day} ${months[date.month - 1]}`;
      // return `${date.day}`;
    },
    calendar_shift: function (offset) {
      let new_scalendar_start = this.calendar_start.clone().add(offset, 'days');
      if (!new_scalendar_start.isBefore(moment(this.startDate).startOf('day'))) //non vado prima del giorno corrente
      {
        this.calendar_start = new_scalendar_start
      }
    },
    clickDay: function (val) {
      this.selected_date_formatted = val.date;
      this.$emit('change',val);
    },
  },
}
</script>

